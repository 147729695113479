/* eslint-disable complexity */
import {
  theme
} from '@vfs-digital-channels/ns-react-components';

import {businessDetailsValidationSchema} from './businessSchema';
import {
  Form,
  Formik
} from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { FormSubmitHelper, SupportPopup } from '../../../components';
import {
  FormContainer,
  FormField
} from '../../../constants';
import {
  mapValues,
  stripEmpty,
  replaceUndefined
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { businessDetailsState, businessDetailsSubmit, useIsMobile } from '../../../recoil';
import { personalDetailsSubmit,fundingDetailsSubmit } from '../../../recoil';

import { useTealium } from '../../../tealium/useTealium';
import styles from './BusinessDetails.module.css';
import {
  getFormFields
} from './businessDetailsConstants';
import { FormBuilder } from '../../../components/FormBuilder';

export const BusinessDetails = () => {
  //const { submitState, setSubmitState } = useOutletContext();
  const { submitState, setSubmitState, backClick, submissionStates, nextClick } = useOutletContext();

  const [details, setDetails] = useRecoilState(businessDetailsSubmit);
  const [personalDetails] = useRecoilState(personalDetailsSubmit);
  const [fundingDetails] = useRecoilState(fundingDetailsSubmit);

  const formRef = React.useRef();
  const isMobile = useRecoilValue(useIsMobile);
  const resetBusinessDetails = useResetRecoilState(businessDetailsState);
  const errorInSubmittedDetails = useFindErrorOnState([businessDetailsSubmit], () => {
    setSubmitState(submissionStates.default);
    resetBusinessDetails();
  });
  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    regNumber: details.regNumber || '',
    businessType: details.businessType || personalDetails.address.businessType,
    unitNumber: details?.address?.unitNumber,
    streetName:  details?.address?.streetName,
    suburb: details?.address?.suburb ,
    postalCode: details?.address?.postalCode,
    province: details?.address?.province,
    industry: details?.industry,
    name: details?.address?.postalCode,
    vatNumber: details.vatNumber || '',
    landline: details.landline || '',
    website: details.website || '',
    country: details?.address?.country,
    isPersonalAddress: details.isPersonalAddress || false,
    buildingType: details?.address?.buildingType

  }), [details]);

  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;
  const businessType =fundingDetails?.businessType;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.businessDetails1.formStart());
  };

  useEffect(() => {
    track(events.businessDetails1.pageView());
  }, []);

  const formatFormData = (formData) => {
    const address = {
      street: formData.streetName || null,
      buildingType: formData.buildingType || null,
      unitNumber: formData.unitNumber || null,
      building: formData.buildingName || null,
      suburb: formData.suburb || null,
      city: formData.city || null,
      province: formData.province || null,
      postalCode: formData.postalCode || null,
      country: formData.country || 'South Africa'
    };

    const formattedData = {
      businessType: businessType || null,
      name: formData.name || null,
      regNumber: formData.regNumber || null,
      vatNumber: formData.vatNumber || null,
      industry: formData.industry || null,
      tradingAs: formData.differentBusinessName ? formData.tradingAs : null,
      revenueBracket: formData.revenueBracket || null,
      timeInBusiness: formData.timeInBusiness || null,
      landline: formData.landline || null,
      website: formData.website || null,
      address: address
    };

    return mapValues(stripEmpty(formattedData));
  };

  const formSubmit = (formData) => {
    track(events.businessDetails1.formEnd(formData.industry?.value));
    setDetails(formatFormData({...formData, isBusinessVerified: true}));
    return details;
  };

  useEffect(() => {
    if (submitState === submissionStates.submitting && !errorInSubmittedDetails?.contents) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [submitState, errorInSubmittedDetails]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>
      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validate={(values) => {
            try {
              businessDetailsValidationSchema.validateSync(values, {
                abortEarly: false,
                context: { businessType }
              });
              return {};
            } catch (error) {
              return error.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
              }, {});
            }
          }}
          validateOnBlur={true}
          validateOnMount={true}
          innerRef={formRef}

        >
          {({ values, errors, touched}) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>
                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />
                <FormBuilder
                  fields={getFormFields(fundingDetails?.businessType)}
                  title="Tell us about your business"
                  subtitle="Please provide the below details about your business"
                  isMobile={isMobile}
                  values={values}
                  FormField={FormField}
                  errors={errors}
                  personalAddress={personalDetails?.address}
                  submitState={submitState}
                  setSubmitState={setSubmitState}
                  backClick={backClick}
                  isDetailsCaptured={true}
                  submissionStates={submissionStates}
                  nextClick={nextClick}
                  touched={touched}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
