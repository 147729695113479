import { Button } from '@vfs-digital-channels/ns-react-components';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { SuccessIcon } from '../../assets';
import {
  Colors,
  CommonDetails
} from '../../constants';

import dayjs from 'dayjs';
import { useTealium } from '../../tealium/useTealium';
import styles from './OtpEntryModal.module.css';

const OTP_LENGTH = 6;
const RE_SINGLE_NUMERIC = new RegExp(/^[0-9]{1}$/);
const RE_SIX_NUMERIC = /^[0-9]{6}$/;

const calculateTimeLeft = (data) => {

  if (!data || isNaN(data)) {
    return null;
  }
  const now = dayjs();
  const blockedUnixTime = dayjs.unix(data);
  const difference = blockedUnixTime?.diff?.(now);

  if (difference <= 0 || !difference || isNaN(difference)) {
    return 0;
  }

  const minutes = Math.floor(difference / 1000 / 60);
  const seconds = Math.floor((difference / 1000) % 60);

  return `${minutes}:${String(seconds).padStart(2, '0')}`;
};

const AttemptsExceeded = ({
  resendAttemptsExceeded,
  otpAttemptsExceeded,
  timeLeft
}) => {

  const isAttemptBlocked = otpAttemptsExceeded || resendAttemptsExceeded;
  const isBothAttempBlocked = otpAttemptsExceeded && resendAttemptsExceeded;

  return (
    <div className={styles.attemptsTextContainer}>
      {isAttemptBlocked && timeLeft ? (
        <div className={styles.attemptsTextWrapper}>

          {otpAttemptsExceeded && !isBothAttempBlocked && (
            <div className={styles.incorrectOtpMsg}>
              OTP attempts exceeded
            </div>
          )}
          {resendAttemptsExceeded && !isBothAttempBlocked && (
            <div className={styles.incorrectOtpMsg}>
              OTP resend exceeded
            </div>
          )}
          {isBothAttempBlocked && (
            <div className={styles.incorrectOtpMsg}>
              OTP attempts and resend exceeded
            </div>
          )}
          <>
            <div className={styles.incorrectOtpBlockedMsg}>
              Please try again in:
            </div>
            <div className={styles.incorrectOtpTimeout}>
              {timeLeft}
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

AttemptsExceeded.propTypes = {
  otpAttemptsExceeded: PropTypes.bool,
  resendAttemptsExceeded: PropTypes.bool,
  timeLeft: PropTypes.any
};

const AttemptIncorrect = () => (
  <div className={styles.attemptsTextContainer}>
    <div className={styles.warningImg}>
      <div className={styles.exclamationLine} />
      <div className={styles.exclamationDot} />
    </div>
    <div className={styles.incorrectOtpMsg}>
      Incorrect OTP - Try again
    </div>
  </div>
);

const OTPExpired = () => (
  <div className={styles.attemptsTextContainer}>
    <div className={styles.warningImg}>
      <div className={styles.exclamationLine} />
      <div className={styles.exclamationDot} />
    </div>
    <div className={styles.incorrectOtpMsg}>
      Your OTP has expired. Request resend
    </div>
  </div>
);

const OTPResent = () => (
  <div className={styles.attemptsTextContainer}>
    <img src={SuccessIcon} alt="success icon" style={{ height: 30, width: 30 }} />
    <div className={styles.incorrectOtpMsg}>
      OTP has been resent
    </div>
  </div>
);

const ControlArea = ({
  otp,
  resendOtp,
  submitOtp,
  otpResponse,
  resendAttemptsExceeded,
  verifyOtpResponse,
  timeLeft
}) => {

  const isOtpNumeric = useMemo(() => RE_SIX_NUMERIC.test(otp), [otp]);
  const otpPending = useRef(false);

  useEffect(() => {
    if (otpResponse) {
      otpPending.current = false;
    }
  }, [otpResponse]);

  const resendOtpRequest = () => {

    if (!otpPending.current) {
      otpPending.current = true;
      if (typeof resendOtp === 'function') {
        resendOtp();
      }
    }
  };

  const continueClick = () => {
    if (typeof submitOtp === 'function') {
      submitOtp(otp);
    }
  };

  return (
    <>
      {verifyOtpResponse?.message === 'OTPExpired' ? null : (
        <Button
          className={isOtpNumeric ? styles.continueBtn : styles.continueBtnDisabled}
          disabled={!isOtpNumeric}
          as={'button'}
          onClick={continueClick}
        >
          Continue
        </Button>
      )}
      {(resendAttemptsExceeded && timeLeft) ? null :
        <div className={styles.resendOtpText}>
          Didn’t receive an OTP?
          <span
            className={styles.resendLink}
            onClick={resendOtpRequest}
            onKeyDown={resendOtpRequest}
            role='presentation'
          >
            Resend
          </span>
        </div>
      }
    </>
  );
};

ControlArea.propTypes = {
  otp: PropTypes.string.isRequired,
  submitOtp: PropTypes.func,
  resendOtp: PropTypes.func,
  otpResponse: PropTypes.object,
  verifyOtpResponse: PropTypes.object,
  resendAttemptsExceeded: PropTypes.bool,
  timeLeft: PropTypes.any
};

const SupportArea = () => {

  const triggerEmailClient = () => {
    window.location.href = `mailto:${CommonDetails.contactUsEmail}`;
  };

  return (
    <div className={styles.needAssistanceBlock}>
      <div className={styles.needAssistanceTxt}>
        Need assistance?
      </div>
      <div className={styles.contactUsLink}>
        Please contact {CommonDetails.contactUsMsisdn} or
        email <span style={{ color: Colors.Primary4 }}
          onClick={triggerEmailClient}
          onKeyDown={triggerEmailClient}
          role='presentation'
        > <u> {CommonDetails.contactUsEmail} </u>
        </span> for assistance
      </div>
    </div>
  );
};

export const OtpEntryModal = ({
  maskedContact,
  verifyOtpResponse,
  otpResponse,
  resendOtp,
  submitOtp,
  resetAllResponse,
  onView
}) => {

  const [otp, setOtp] = useState(' '.repeat(OTP_LENGTH));
  const valueItems = useMemo(() => otp && otp.split(''), [otp]);
  const [resendState, setResendState] = useState(false);

  const otpAttemptsExceeded = useMemo(() => !!(verifyOtpResponse?.errorData?.otpAttemptsRemaining === 0 ||
    verifyOtpResponse?.message === 'OTPAttemptsBlocked' || otpResponse?.message === 'OTPAttemptsBlocked'), [otpResponse, verifyOtpResponse]);

  const resendAttemptsExceeded = useMemo(() => !!(otpResponse?.otpResendRemaining === 0 ||
    otpResponse?.message === 'OTPResendBlocked'), [otpResponse]);

  const resetOtp = () => setOtp(' '.repeat(OTP_LENGTH));
  const { track, events } = useTealium();

  useEffect(() => {
    if (otpResponse) {
      resetOtp();
    }
  }, [otpResponse]);

  useEffect(() => {
    if (verifyOtpResponse?.errorData?.code || verifyOtpResponse?.message === 'OTPExpired') {
      resetOtp();
    }
  }, [verifyOtpResponse]);

  useEffect(() => {
    onView();
  }, []);

  const focusToNextInput = (target) => {

    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target) => {

    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValue = RE_SINGLE_NUMERIC.test(targetValue);

    targetValue = isTargetValue ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
      setOtp(newValue);
      if (isTargetValue) {
        focusToNextInput(target);
      }
    } else if (targetValueLength === otp.length) {
      setOtp(targetValue);
      target.blur();
    }

    return '';
  };

  const inputOnKeyDown = (e) => {

    const { key, target } = e;

    const targetValue = target.value;

    if (key === 'ArrowRight' || key === 'ArrowDown') {

      e.preventDefault();

      return focusToNextInput(target);

    } else if (key === 'ArrowLeft' || key === 'ArrowUp') {

      e.preventDefault();

      return focusToPrevInput(target);

    } else if (targetValue === ' ' && key === 'Backspace') {

      return focusToPrevInput(target);
    } else if (key === 'Enter' && RE_SIX_NUMERIC.test(otp) && !otpAttemptsExceeded && verifyOtpResponse?.message !== 'OTPExpired') {

      e.preventDefault();
      if (typeof submitOtp === 'function') {
        submitOtp(otp);
      }
    }

    target.setSelectionRange(0, targetValue.length);

    if (key !== 'Backspace' || targetValue !== '') {

      return '';
    }

    return focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    const prevInputEl =
      target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    return target.setSelectionRange(0, target.value.length);
  };

  const handlePaste = (e) => {

    e.preventDefault();

    const pasteData = e.clipboardData.getData('text/plain').slice(0, OTP_LENGTH).trim();

    const pasteDataLength = pasteData.length;

    if (pasteDataLength !== OTP_LENGTH || !RE_SIX_NUMERIC.test(pasteData)) {
      return;
    }

    const lastElement = document.getElementById(`otpInput-${OTP_LENGTH}`);

    if (lastElement) {
      lastElement?.focus?.();
    }

    setOtp(pasteData);
  };

  const requestResendOtp = () => {
    setResendState(true);
    resendOtp();
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const isAttemptBlocked = otpAttemptsExceeded || resendAttemptsExceeded;

  /* eslint-disable consistent-return */
  useEffect(() => {

    const verifyRespTime = calculateTimeLeft(verifyOtpResponse?.errorData?.minutesBlocked);
    const otpRespTime = calculateTimeLeft(otpResponse?.errorData?.minutesBlocked);
    const otpInitialRespTime = calculateTimeLeft(otpResponse?.minsToBlock);
    const verifyInitialRespTime = calculateTimeLeft(verifyOtpResponse?.errorData?.minsToBlock);

    let timeToBlock;

    if (verifyRespTime !== null) {
      timeToBlock = verifyOtpResponse?.errorData?.minutesBlocked;
    } else if (otpRespTime !== null) {
      timeToBlock = otpResponse?.errorData?.minutesBlocked;
    } else if (otpInitialRespTime !== null) {
      timeToBlock = otpResponse?.minsToBlock;
    } else if (verifyInitialRespTime !== null) {
      timeToBlock = verifyOtpResponse?.errorData?.minsToBlock;
    }

    if (isAttemptBlocked) {
      track(events.OTPExceeded());

      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(timeToBlock));
      }, 1000);

      return () => clearInterval(timer);
    }

  }, [verifyOtpResponse, otpResponse, isAttemptBlocked]);

  useEffect(() => {

    if (timeLeft === 0) {
      setTimeLeft(null);
      resetAllResponse();
    }
  }, [timeLeft]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.mainContainer}>
        <div className={styles.heading}>
          We sent you an SMS
        </div>
        <div className={styles.subHeading}>
          <div>
            Please enter {OTP_LENGTH} digit verification code sent to
          </div>
          <div>
            <div className={styles.subHeadingHiddenMsisdn}>
              {maskedContact || otpResponse?.contact}
            </div>
          </div>
        </div>
        <div className={styles.otpEntryContainer}>
          {valueItems.map((digit, idx) => (
            <input
              type='text'
              key={idx}
              maxLength={1}
              className={`${styles.otpEntryBox} ${RE_SINGLE_NUMERIC.test(digit) ? styles.otpEntryBoxValue : ''}`}
              onPaste={handlePaste}
              onChange={(e) => inputOnChange(e, idx)}
              onKeyDown={inputOnKeyDown}
              onFocus={inputOnFocus}
              value={digit}
              id={`otpInput-${idx + 1}`}
            />
          ))}
        </div>
        <MiddleArea
          verifyOtpResponse={verifyOtpResponse}
          otpResponse={otpResponse}
          timeLeft={timeLeft}
          resendAttemptsExceeded={resendAttemptsExceeded}
          otpAttemptsExceeded={otpAttemptsExceeded}
          resendState={resendState}
        />
        {otpAttemptsExceeded
          ? <SupportArea />
          : <ControlArea
            otp={otp}
            resendOtp={requestResendOtp}
            submitOtp={submitOtp}
            resendAttemptsExceeded={resendAttemptsExceeded}
            verifyOtpResponse={verifyOtpResponse}
            timeLeft={timeLeft}
            otpResponse={otpResponse} />
        }
      </div>
    </div>
  );
};

OtpEntryModal.propTypes = {
  submitOtp: PropTypes.func,
  resendOtp: PropTypes.func,
  maskedContact: PropTypes.string,
  verifyOtpResponse: PropTypes.object,
  otpResponse: PropTypes.object,
  resetAllResponse: PropTypes.func,
  onView: PropTypes.func
};

const MiddleArea = ({
  verifyOtpResponse,
  otpResponse,
  timeLeft,
  resendAttemptsExceeded,
  otpAttemptsExceeded,
  resendState
}) => {

  return (
    <>
      {verifyOtpResponse?.errorData?.otpAttemptsRemaining > 0 && !otpAttemptsExceeded && (
        <div className={styles.attemptStep}>
          {verifyOtpResponse?.errorData?.otpAttemptsRemaining} more attempts remaining
        </div>
      )}
      {resendState && otpResponse?.otpResendRemaining > 0 && !resendAttemptsExceeded && (
        <div className={styles.attemptStep}>
          {otpResponse?.otpResendRemaining} more resend attempts remaining
        </div>
      )}
      {(verifyOtpResponse?.message === 'CodeMismatch' && verifyOtpResponse?.errorData?.otpAttemptsRemaining > 0) ? <AttemptIncorrect />
        : (otpResponse?.isResend && otpResponse?.otpResendRemaining > 0) ? <OTPResent />
          : verifyOtpResponse?.message === 'OTPExpired' ? <OTPExpired />
            : null
      }
      {(resendAttemptsExceeded || otpAttemptsExceeded) &&
        <AttemptsExceeded
          timeLeft={timeLeft}
          resendAttemptsExceeded={resendAttemptsExceeded}
          otpAttemptsExceeded={otpAttemptsExceeded}
        />
      }

    </>
  );
};

MiddleArea.propTypes = {
  verifyOtpResponse: PropTypes.object,
  otpResponse: PropTypes.object,
  timeLeft: PropTypes.any,
  resendAttemptsExceeded: PropTypes.bool,
  otpAttemptsExceeded: PropTypes.bool,
  resendState: PropTypes.bool,
  onView: PropTypes.func
};
