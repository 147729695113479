import {
  InputField
} from '@vfs-digital-channels/ns-react-components-new';
import styled from 'styled-components';

export const FormikInputField = styled(InputField)`
  width: 100%;  // Take full width of parent
  height: 60px;
  margin-bottom: 10px;
  & > :nth-child(1) {
    background-color: var(--color-white);
    border-color: var(--color-grey-200);
    width: 100%;  // Take full width of parent
    height: 60px;
  }
`;