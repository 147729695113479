import React, {
  useEffect, useMemo
} from 'react';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { StaticRoutes } from '../../Router';
import { useRecoilValue } from 'recoil';
import { userFirstName } from '../../recoil';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { Modal } from '../../components/modal/modal';
import { getErrorStatus } from '../../api/vodaLend/axiosInstance';
import { Colors, CommonDetails } from '../../constants';
import { Documents } from '../../assets';

const styles = {
  copyBtn: {
    borderRadius: '50%',
    border: `1px solid ${Colors.GreyScale13}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    cursor: 'pointer'
  },
  docImg: {
    width: 25,
    height: 25
  },
  traceIdSection: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  traceIdKey: { marginRight: 5 }
};

export const ErrorFallback = ({ error, resetErrorBoundary }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const name = useRecoilValue(userFirstName);

  const traceId = useMemo(() => {
    const innerId = error?.response?.headers?.['x-amzn-trace-id'] || '';
    if (innerId) {
      return innerId;
    }
    const baseId = error?.response?.headers?.['x-amzn-x-amzn-trace-id']?.split?.('=')?.[1] || '';
    if (baseId) {
      return baseId;
    }
    return '';
  }, [error]);

  const status = getErrorStatus(error);

  useEffect(() => {
    console.error(error);

    if (error.message === 'VlUnauthorized') {
      resetErrorBoundary();
      navigate(name ? StaticRoutes.login : StaticRoutes.registration, {
        state: { returnPath: location.pathname }
      });
    }
  }, [error]);

  const reloadPage = () => {
    resetErrorBoundary();
    window.location.reload();
  };

  const handleBackClick = () => {
    resetErrorBoundary();
    navigate(-1);
    setTimeout(()=> {window.location.reload();}, 0);
  };

  const copyText = () => navigator.clipboard.writeText(traceId);

  return (
    <Modal title={'Error'}
      onClose={reloadPage}
      type={'error'}
      actions={[
        <Button key={'back'} onClick={handleBackClick}>Back</Button>
      ]}
    >
      {!traceId &&
        <>
          {error.status &&
            <p><b>Status: {status}</b></p>
          }

          <p>Oops! Something has gone wrong.</p>
          <p>{error.statusText || error.message}</p>
        </>
      }

      {traceId &&
        <>
          {error.status &&
            <p><b>Status: {status}</b></p>
          }

          <p>{error.statusText || error.message}</p>

          <div style={styles.traceIdSection}>
            <p style={styles.traceIdKey}>
              <b>Trace ID: </b> {traceId}
            </p>
            <div
              style={styles.copyBtn}
              onClick={copyText}
              onKeyDown={copyText}
              role='button'
              tabIndex={0}
            >
              <img src={Documents} alt="copy" style={styles.docImg} />
            </div>
          </div>

          <p>Please contact <a
            href={`mailto:${CommonDetails.contactUsEmail}`}>{CommonDetails.contactUsEmail}</a> | <a href={`tel:${CommonDetails.contactUsMsisdn.replace(/\s/g,'')}`}>{CommonDetails.contactUsMsisdn}</a></p>
        </>
      }
    </Modal>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired
};