import * as yup from 'yup';
import {
  IndustryTypes,
  closedCorpCompanyRegNumRegex,
  privateCompanyRegNumRegex,
  publicCompanyRegNumRegex,
  vatRegex
} from '../../../constants';

export const businessSchema = yup.object().shape({
  registrationNumber: yup.string()
    .required('Business Registration Number is required')
    .min(5, 'Registration Number must be at least 5 characters')
    .max(20, 'Registration Number cannot exceed 20 characters'),

  buildingType: yup.string()
    .required('Building Type is required'),

  unitNumber: yup.number()
    .typeError('Unit Number must be a number')
    .positive('Unit Number must be positive')
    .integer('Unit Number must be a whole number')
    .nullable(),

  streetName: yup.string()
    .required('Street Name is required'),

  suburb: yup.string()
    .required('Suburb is required'),

  postalCode: yup.string()
    .matches(/^\d{4,6}$/, 'Postal Code must be 4-6 digits')
    .required('Postal Code is required'),

  province: yup.string()
    .required('Province is required'),

  category: yup.string()
    .required('Category is required'),

  businessName: yup.string()
    .required('Business Name is required'),

  vatNumber: yup.string()
    .matches(/^\d{9,12}$/, 'VAT Number must be between 9-12 digits')
    .nullable()
    .test('valid-vatNumber', 'VAT Number must be in the format 40xxx56789', (value) => {
      if (!value) {return true;} // Allow nullable VAT numbers
      return vatRegex.test(value);
    }),

  phoneNumber: yup.string()
    .matches(/^\+?[0-9]{10,15}$/, 'Phone Number must be 10-15 digits')
    .required('Phone Number is required'),

  websiteName: yup.string()
    .url('Enter a valid website URL')
    .nullable()
});

export const businessDetailsValidationSchema = yup.object({
  businessType: yup
    .mixed()
    .test('businessType', 'Type of business is a required field', (item) => !!item?.value),
  name: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value === 'Sole Proprietorship',
      then: () => yup.string().trim().min(1, 'Business Name must be a valid Business Name consisting of at least 1 characters'),
      otherwise: () => yup.string().trim().min(1, 'Business Name must be a valid Business Name consisting of at least 1 characters').required('Business Name is a required field')
    }),
  differentBusinessName: yup.boolean(),
  tradingAs: yup.string(),
  regNumber: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value !== 'Sole Proprietorship',
      then: () => yup.string().required('Business Registration Number is a required field')
        .test((value, context) => {

          const regNumber = value;
          const { businessType } = context?.parent || {};
          const { createError } = context || {};

          if (businessType?.value === 'Private Company' && !privateCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/07' });
          }

          if (businessType?.value === 'Close Corporation' && !closedCorpCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/23' });
          }

          if (businessType?.value === 'Public Company' && !publicCompanyRegNumRegex.test(regNumber)) {
            return createError({ message: 'Business Registration Number must be a valid numerical value in the format 2001/xxxxxx/06' });
          }
          return true;
        }),
      otherwise: () => yup.string()
    }),
  vatNumber: yup
    .string()
    .when('businessType', {
      is: (businessType) => businessType?.value === 'Sole Proprietorship',
      then: () => yup
        .string()
        .required('VAT Number is a required field')
        .matches(vatRegex, 'VAT Number must be a valid numerical value in the format 40xxx56789'),
      otherwise: () => yup.string()
    }),
  industry: yup
    .mixed()
    .test('industry', 'Please select an option from drop down list', (item) => !!IndustryTypes.find((d) => d.value === item?.value))
    .required('What industry are you in is a required field')
});