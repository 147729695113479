import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Colors,
  Fonts
} from '../../constants';
import {
  ConfirmModal
} from '../../components';
import {
  useIsMobile,
  documentDelete,
  fetchDoc,
  singleDocFetchState
} from '../../recoil';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { DeleteIcon, CloseIcon, Download, PdfIcon } from '../../assets';
import { formatBytes } from '../../helpers';
import { useFindErrorOnState } from '../../hooks/useFindErrorOnState';

const styles = {
  documentName: {
    fontSize: '1em',
    fontFamily: Fonts.RobotoMedium,
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  documentCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20
  },
  removeImg: {
    width: 20,
    marginRight: 5
  },
  fileImg: {
    width: 40,
    marginLeft: 5
  },
  downloadImg: {
    width: 20,
    marginRight: 5,
    cursor: 'pointer'
  },
  documentLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%'
  },
  documentRightSection: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 10,
    fontFamily: Fonts.Roboto,
    color: Colors.GreyScale12,
    fontSize: 14,
    cursor: 'pointer',
    alignItems: 'flex-end'
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%'
  },
  documentLabel: {
    fontFamily: Fonts.Roboto,
    color: Colors.GreyScale10,
    fontSize: 14,
    marginLeft: 10,
    fontStyle: 'italic'
  },
  documentSize: {
    display: 'flex',
    fontFamily: Fonts.Roboto,
    color: Colors.GreyScale8,
    fontSize: 14,
    alignItems: 'flex-end'
  }
};

export const DocumentCard = ({ docName, docId, docSize }) => {

  const deleteFile = useSetRecoilState(documentDelete);
  const isMobile = useRecoilValue(useIsMobile);
  const [isOpenDocumentDelete, setOpenDocumentDelete] = useState(false);
  const [file, fetchFile] = useRecoilState(fetchDoc);
  const isFileDownloadComplete = useRef(false);
  const resetFetchedDoc = useResetRecoilState(singleDocFetchState);
  useFindErrorOnState([fetchDoc], () => {
    resetFetchedDoc();
  });

  useEffect(() => {
    if (file?.error) {
      fetchFile(null);
      return;
    }
    if (file?.blobFile && file?.fileName && !isFileDownloadComplete.current) {

      isFileDownloadComplete.current = true;
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = file.blobFile;
      anchor.setAttribute('download', file.fileName);
      anchor.click();
      try {
        if (anchor.parentNode) {
          anchor.parentNode.removeChild(anchor);
        } else {
          anchor.remove();
        }
      } catch {
        fetchFile(null);
      }
      fetchFile(null);
    }
  }, [file]);

  const openDeletePopup = () => {
    setOpenDocumentDelete(true);
  };

  const deleteDocument = () => {
    deleteFile(docId);
    setOpenDocumentDelete(false);
  };

  const downloadFile = async () => {
    isFileDownloadComplete.current = false;
    fetchFile(docId);
  };

  return (
    <>
      <div style={styles.documentCard}>
        <div style={styles.documentLeftSection}>
          <img
            src={PdfIcon}
            alt="PDF Icon"
            style={styles.fileImg}
          />
          <div style={styles.documentContainer}>
            <div style={styles.documentLabel}>Bank Statment</div>
            <div style={styles.documentName}>
              {docName}
            </div>
          </div>
          <div style={styles.documentSize}>{formatBytes(docSize)}</div>
        </div>

        <div style={styles.documentRightSection}>
          <div
            style={styles.iconGroup}
            role='presentation'
            onClick={downloadFile}
            onKeyDown={downloadFile}
          >
            <img
              src={Download}
              alt="Download Icon"
              style={styles.downloadImg}
            />
            {!isMobile && <div>Download</div>}
          </div>
          <div
            style={styles.iconGroup}
            role='presentation'
            onClick={() => openDeletePopup()}
            onKeyDown={() => openDeletePopup()}
          >
            <img
              src={DeleteIcon}
              alt="Delete Icon"
              style={styles.removeImg}
            />
            {!isMobile && <div>Remove</div>}
          </div>
        </div>
      </div>

      {isOpenDocumentDelete && (
        <ConfirmModal
          closeCallback={() => setOpenDocumentDelete(false)}
          icon={CloseIcon}
          title={'Are you sure?'}
          subTitle={'You are about the delete a document.'}
          acceptLabel={'Delete document'}
          cancelLabel={'Cancel'}
          onAcceptClick={deleteDocument}
        />
      )}
    </>
  );
};

DocumentCard.propTypes = {
  docName: PropTypes.string.isRequired,
  docId: PropTypes.string,
  docSize: PropTypes.number
};