import dayjs from 'dayjs';
import { atom, selector } from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchStateAdvanceRepayment = async () => await vodaLend.getAdvanceRepayment();

const fetchStateAdvance = async () => await vodaLend.getAdvance();

const transactionHistoryBtaDefault = selector({
  key: 'transactionHistoryBtaDefault',
  get: async () => await fetchStateAdvanceRepayment()
});

const summaryBtaDefault = selector({
  key: 'summaryBtaDefault',
  get: async () => await fetchStateAdvance()
});

const transactionHistoryBtaState = atom({
  key: 'transactionHistoryBtaState',
  default: transactionHistoryBtaDefault
});

const summaryBtaState = atom({
  key: 'summaryBtaState',
  default: summaryBtaDefault
});

export const btaTransactionHistory = selector({
  key: 'btaTransactionHistory',
  get: async ({ get }) => {

    const data = await get(transactionHistoryBtaState) || { records: [], total: {} };

    return {
      records: data.records.map((d) => ({
        displayDate: d.dueDate ? dayjs(d.dueDate).format('DD-MMM-YY') : '',
        capital: d.principleAmount,
        cost: d.riskCostAmount,
        paymentAmount: d.paymentAmount,
        repaymentState: d.repaymentState,
        paidAmount: d.amountReceived,
        arrears: d.arrearsAmount,
        earlySettlement: d.projectedSettlementAmount,
        paidDate: d.dateReceived ? dayjs(d.dateReceived).format('DD-MMM-YY') : ''
      })),
      total: {
        capital: data.total?.principleAmount,
        cost: data.total?.riskCostAmount,
        paymentAmount: data.total?.paymentAmount,
        paidAmount: data.total?.amountReceived,
        arrears: data.total?.arrearsAmount,
        earlySettlement: data.total?.projectedSettlementAmount
      }
    };
  }
});

export const btaAccountSummary = selector({
  key: 'btaAccountSummary',
  get: async ({ get }) => {

    const d = await get(summaryBtaState);

    if (!d) {
      return d;
    }

    return {
      advanceId: d.id,
      advanceAmount: d.advanceAmount,
      arrearsAmount: d.arrearsAmount,
      remainingCapital: d.outstandingCapital,
      remainingBalance: d.outstandingBalance,
      // earlySettleAmount: 2012345, // TODO: uncomment this in future when we get the related field from LLL (eugene)
      totalRepaid: d.repaidValue,
      totalOutstanding: d.outstandingBalance
    };
  }
});
