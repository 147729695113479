export const personalDetailsKeys = [
  'title',
  'positionInCompany',
  'residentialStatus',
  'idType',
  'whenMovedIn',
  'postalCode',
  'provinces',
  'country',
  'suburb',
  'buildingType'
];