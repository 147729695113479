import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import { StaticRoutes } from '../../../../Router';
import styles from './increaseLimitSuccessModal.module.css';
import { useRecoilValue } from 'recoil';
import { authDetail } from '../../../../recoil';
import { useModal } from '../../../../components/modalController/useModal';
import PropTypes from 'prop-types';

export const IncreaseLimitSuccessModal = ({onSuccess,onClick}) => {
  const [, closeModal] = useModal();
  const userDetails = useRecoilValue(authDetail);

  useEffect(() => {
    onSuccess();
  }, []);

  const handleClick= () => {
    onClick();
    closeModal();
  };
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Capital Facility Limit Increase application submitted
      </div>
      <div className={styles.content}>
        <div>Thank you {userDetails.firstName} {userDetails.lastName}, for your Capital Facility Limit Increase application.</div>
        <div>We will be in touch, if there is anything we need to clarify.</div>
      </div>
      <div className={styles.content}>
        <div>You can view the status of your Capital Facility under <Link onClick={handleClick} to={StaticRoutes.applications} className={styles.url}>My
          Applications</Link> which can be accessed at anytime.</div>
      </div>
    </div>
  );
};

IncreaseLimitSuccessModal.propTypes = {
  onSuccess: PropTypes.func,
  onClick: PropTypes.func
};