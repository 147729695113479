import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => {
  const response = await vodaLend.getFundingDetails();
  return response.data;
};

export const fundingDetailsFetch = selector({
  key: 'fundingDetailsFetch',
  get: async () => await fetchState()
});

const fundingDetailsDefault = selector({
  key: 'fundingDetailsDefault',
  get: async () => await fetchState()
});

export const fundingDetailsState = atom({
  key: 'fundingDetailsState',
  default: fundingDetailsDefault
});

export const fundingDetailsSubmit = selector({
  key: 'fundingDetailsSubmit',
  get: async ({ get }) => await get(fundingDetailsState),
  set: ({ get, set }, inputData) => {

    const cur = get(fundingDetailsState) || {};

    if (inputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...inputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(fundingDetailsState, data);
      return;
    }
    if (Object.keys(cur).length === 0) {
      set(fundingDetailsState, {
        ...cur,
        ...inputData
      });
    } else {
      set(fundingDetailsState, {
        ...inputData
      });
    }
  }
});
