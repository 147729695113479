import { handleErrorResponse } from '../../helpers/handleErrorResponse';
import { axiosInstance } from './axiosInstance';

export const removeActiveSession = async () => {
  let response;

  try {
    response = await axiosInstance.post('/vl-oauth-code-exchange/logout');
  } catch (err) {
    response = handleErrorResponse(err);
  }

  return response;
};
