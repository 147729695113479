import React from 'react';
import PropTypes from 'prop-types';
import styles from './typography.module.css';

const levels = [1, 2, 3, 4, 5, 6];
const families = ['light', 'regular', 'medium', 'bold', 'black'];
const weights = ['light', 'regular', 'medium', 'bold', 'black'];
const sizes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'b1', 'b2', 'b3'];
const alignments = ['left', 'center', 'right', 'justify'];
const spacings = [1, 2, 3, 4, 5, 6, 7, 8];

export const Typography = ({ className, level, family, weight, size, align, inline, children, spacing,...props }) => {
  let Tag = inline ? 'span' : 'p';
  const hasLevel = levels.includes(level);

  if (hasLevel) {
    Tag = `h${level}`;
  }

  const calculatedClassName = () => {
    const classNames = [styles.typography];

    if (inline && hasLevel) {
      classNames.push(styles.inline);
    }

    if (families.includes(family)) {
      classNames.push(styles[`family-${family}`]);
    }

    if (weights.includes(weight)) {
      classNames.push(styles[`weight-${weight}`]);
    }

    if (sizes.includes(size)) {
      classNames.push(styles[size]);
    }

    if (alignments.includes(align)) {
      classNames.push(styles[`align-${align}`]);
    }

    if (className) {
      classNames.push(className);
    }

    if (spacings.includes(spacing)) {
      classNames.push(styles[`mb-${spacing}`]);
    }

    return classNames.join(' ');
  };

  return <Tag className={calculatedClassName()} {...props}>{children}</Tag>;
};

Typography.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf(levels),
  family: PropTypes.oneOf(families),
  weight: PropTypes.oneOf(weights),
  size: PropTypes.oneOf(sizes),
  align: PropTypes.oneOf(alignments),
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf(spacings)
};

Typography.defaultProps = {
  spacing: 'm'
};