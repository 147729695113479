import { pageName } from '../constants';

const baseError = {
  page_section: 'business advance',
  page_channel: 'vodapay: business advance',
  page_subdomain: 'business advance',
  page_type: 'website',
  link_id: 'vodapay: business advance: error'
};

const eventNames = {
  page: 'page_error',
  event: 'event_error',
  form: 'form_error'
};

const errorTypes = {
  system: 'system error',
  user: 'user error'
};

export const error = (eventName, urlPath, errorMessage, errorCode = null, errorType = null) => {
  const commonError = {
    ...baseError,
    event_name: eventNames[eventName],
    page_name: `vodapay: business advance: opf: ${pageName[urlPath]}`,
    page_url: window.location.href
  };

  if (eventNames[eventName] === 'page_error') {
    return {
      ...commonError,
      page_error_name: errorMessage,
      page_error_code: errorCode || 'n/a',
      page_error_type: errorTypes[errorType] || errorTypes.system
    };
  }

  if (eventNames[eventName] === 'form_error') {
    return {
      ...commonError,
      form_error_name: errorMessage,
      form_error_code: errorCode || 'n/a',
      form_error_type: errorTypes[errorType] || errorTypes.system
    };
  }

  return {
    ...commonError,
    event_error_name: errorMessage,
    event_error_code: errorCode || 'n/a',
    event_error_type: errorTypes[errorType] || errorTypes.system
  };
};

export const OTPExceeded = () => ({
  event_name: 'event_error',
  page_name: 'vodapay: business advance: opf: otp',
  page_url: window.location.href,
  page_section: 'business advance',
  page_channel: 'vodapay: business advance',
  page_subdomain: 'business advance',
  page_type: 'website',
  link_id: 'vodapay: business advance: error',
  event_error_name: 'OTP resend exceeded',
  event_error_code: 'n/a',
  event_error_type: 'user error'
});
