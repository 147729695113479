import React, {
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react';
import {
  Colors, CommonDetails,
  Fonts
} from '../../../constants';
import {
  DocumentCard,
  FileUpload,
  Footer
} from '../../../components';
import {
  documentData,
  useIsMobile,
  documentSync,
  documentsByType,
  useShowToast,
  documentsListState,
  toasts
} from '../../../recoil';
import {
  useRecoilValue,
  useRecoilCallback,
  useResetRecoilState
} from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { StaticRoutes } from '../../../Router';
import { formatBytes } from '../../../helpers';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { useTealium } from '../../../tealium/useTealium';

const DOCTYPE_STATEMENT = 'statement';
const VALID_UPLOAD_BYTES_SIZE = 10 * (1024 * 1024);

const styles = {
  uploadContainer: {
    width: '95%',
    maxWidth: 800,
    minHeight: 211,
    height: 'auto',
    marginTop: 30,
    borderRadius: 20,
    border: `1px solid ${Colors.GreyScale10}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  heading: {
    width: '90%',
    margin: '20px 0px',
    fontSize: 20,
    fontWeight: 400
  },
  financialDetail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    marginBottom: 150
  },
  pageHeading: {
    fontSize: '1.700em',
    fontFamily: Fonts.RobotoMedium,
    lineHeight: '120%',
    letterSpacing: 0.9,
    color: Colors.GreyScale12,
    textAlign: 'center',
    margin: '3vh 1vw'
  },
  fileUploadContainer: {
    width: '90%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 62,
    cursor: 'pointer'
  },
  pageBottomTxtContainer: {
    width: '95%',
    marginTop: 28,
    display: 'flex',
    color: Colors.GreyScale10,
    textAlign: 'center',
    fontSize: 14,
    fontFamily: Fonts.Roboto,
    lineHeight: '130%',
    flexDirection: 'column'
  },
  docInfoBoldTxt: {
    fontFamily: Fonts.RobotoBold
  },
  docInfoContainer: {
    display: 'block',
    flexDirection: 'row',
    margin: 10,
    justifyContent: 'space-between',
    width: '100%'
  },
  sizeFormat: {
    color: Colors.GreyScale10,
    fontSize: 12,
    fontFamily: Fonts.Roboto,
    lineHeight: '140%',
    display: 'flex',
    flexDirection: 'column',
    width: 130
  },
  docInfo: {
    color: Colors.GreyScale10,
    fontSize: 12,
    fontFamily: Fonts.Roboto,
    lineHeight: '140%',
    display: 'flex',
    flexDirection: 'row',
    float: 'left',
    width: 'calc(100% - 135px)',
    marginRight: 5
  },
  docInfoMobile: {
    width: '100%'
  },
  boldText: {
    fontFamily: Fonts.RobotoBold
  },
  custDocDotContainer: {
    marginTop: 5,
    marginRight: 5,
    width: 3
  },
  custDocDot: {
    borderRadius: '50%',
    height: 3,
    width: 3,
    background: Colors.GreyScale10,
    marginTop: 2
  },
  documentCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '95%',
    maxWidth: 800
  },
  errorMessage: {
    width: '90%',
    fontFamily: Fonts.Roboto,
    fontSize: 12,
    color: Colors.Primary5,
    marginBottom: 10
  }
};

export const ProfileDocumentDetails = () => {

  const navigate = useNavigate();
  const statementFileIdRef = useRef(uuidv4());
  const statementFilesList = useRecoilValue(documentsByType(DOCTYPE_STATEMENT));
  const statementFile = useRecoilValue(documentData(statementFileIdRef.current));
  const isMobile = useRecoilValue(useIsMobile);
  const docInfoStyle = useMemo(() => isMobile ? { ...styles.docInfo, ...styles.docInfoMobile } : styles.docInfo, [isMobile]);
  const [isAllowSize, setIsAllowSize] = useState(false);
  const resetToast = useResetRecoilState(toasts);
  const [isAllowType, setIsAllowType] = useState(false);
  const showToast = useShowToast();
  const resetDocList = useResetRecoilState(documentsListState);
  const resetStatementFiile = useResetRecoilState(documentData(statementFileIdRef.current));
  const { track, events } = useTealium();

  useFindErrorOnState([documentsByType(DOCTYPE_STATEMENT), documentData(statementFileIdRef.current)], () => {
    resetDocList();
    resetStatementFiile();
  });

  useEffect(() => {

    if (statementFilesList?.error || statementFile?.error) {
      resetToast();
      return;
    }
  }, [statementFilesList]);

  const goToMainPage = () => {
    navigate(StaticRoutes.profile);
  };

  const uploadFile = useRecoilCallback(({ snapshot, set }) => async (fileRef, docName, docType, file) => {
    set(documentSync({ docName, docType }), file);
    return await snapshot.getPromise(documentData(fileRef.current));
  }, []);

  const uploadStatement = async (statementFile) => {
    if (!(statementFile.size <= VALID_UPLOAD_BYTES_SIZE)) {
      setIsAllowSize(true);
      return;
    }
    if (!(statementFile.type === 'application/pdf')) {
      setIsAllowType(true);
      return;
    }

    const getUniqueFileName = (filename) => {
      if (statementFilesList.find((file) => file.docName === filename)) {
        const splitName = filename.split('.');
        let baseName = splitName[0];
        const extension = splitName[1];
        let pattern = /\((\d+)\)$/;

        if (pattern.test(baseName)) {
          const match = pattern.exec(baseName);
          if (match) {
            let originalDigits = parseInt(match[1]);
            let newDigits = originalDigits + 1;
            let newString = baseName.replace(/\((\d+)\)$/, `(${newDigits})`);
            filename = `${newString}.${extension}`;
            return getUniqueFileName(filename);
          }
        } else {
          baseName += ' (1)';
          filename = `${baseName}.${extension}`;
          return getUniqueFileName(filename);
        }
      }
      return filename;
    };

    const uniqueFilename = getUniqueFileName(statementFile.name);
    await uploadFile(statementFileIdRef, uniqueFilename, DOCTYPE_STATEMENT, statementFile);
    showToast('Upload successful');
    statementFileIdRef.current = uuidv4();
  };

  useEffect(() => {
    if (statementFile) {
      setIsAllowSize(false);
      setIsAllowType(false);
      statementFile.forEach(file => {
        uploadStatement(file);
      });
    }
  }, [statementFile]);

  return (
    <>
      <div style={styles.financialDetail}>
        <div style={styles.pageHeading}>
          Document
        </div>

        <div style={styles.documentCardContainer}>
          {statementFilesList.map((f) => (<DocumentCard key={f.docId} docName={f.docName} docId={f.docId} docSize={f.docSize} />))}
        </div>

        <div style={styles.uploadContainer}>
          <div style={styles.heading}>
            Bank statement upload
          </div>
          <div style={styles.fileUploadContainer}>
            <FileUpload fileId={statementFileIdRef.current} onUpload={() => track(events.uploadDocuments.documentUpload())} />
            <div style={styles.docInfoContainer}>
              <div style={docInfoStyle}>
                <div style={styles.custDocDotContainer}>
                  <div style={styles.custDocDot} />
                </div>
                <div>
                  <span style={styles.docInfoBoldTxt}>Bank statement: </span>
                  A clear copy of your Bank statement not older than 3 months.
                </div>
              </div>
              <div style={styles.sizeFormat}>
                <div style={styles.docSizeLimitInfo}>
                  <span style={styles.boldText}>Maximum size:</span> {formatBytes(VALID_UPLOAD_BYTES_SIZE)}
                </div>
                <div style={styles.docSizeLimitInfo}>
                  <span style={styles.boldText}>File Type:</span> PDF
                </div>
              </div>
            </div>
          </div>
          {isAllowSize &&
            <div style={styles.errorMessage}>*Invalid file size. File size should be smaller than {formatBytes(VALID_UPLOAD_BYTES_SIZE)}.</div>
          }
          {isAllowType &&
            <div style={styles.errorMessage}>*Invalid file type. File type should be PDF.</div>
          }
        </div>

        <div style={styles.pageBottomTxtContainer}>
          <div>
            We use industry-leading encryption and security methods when handling your documents.
          </div>
          <div>
            Your details are not shared with anyone. To find out more, read our <a style={{ color: Colors.Primary4 }} href={CommonDetails.vodalendPrivacyPolicy} target="_blank" rel="noreferrer"><u>privacy
              statement</u></a>.
          </div>
        </div>
      </div>

      <Footer leftCallback={goToMainPage} leftTitle={isMobile ? '' : 'Cancel'} />

    </>
  );
};