import { FormikRadioButton } from '@vfs-digital-channels/ns-react-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useIsMobile } from '../../recoil';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'left',
    margin: '12px 0 10px 0',
    width: '32.6rem',
    maxWidth: '100%'
  },
  mobileContainer : {
    display: 'flex',
    justifyContent: 'inherit',
    margin: '12px 0 0 0',
    width: '32.6rem',
    maxWidth: '100%'
  },
  buttonContainer: {
    margin: '0 20px 0 0',
    display: 'flex',
    alignItems: 'space-between'
  },
  mobileButtonContainer : {
    margin: '0 10px 0 0',
    display: 'flex',
    alignItems: 'space-between'
  }
};

export const FormRadioGroup = ({ name, values,disabled }) => {

  const isMobile = useRecoilValue(useIsMobile);

  return (
    <div style={ isMobile ? styles.mobileContainer : styles.container}>
      {
        Object.entries(values).map(([k, v]) => (
          <div key={k} style={isMobile ? styles.mobileButtonContainer : styles.buttonContainer} >
            <FormikRadioButton
              name={name}
              value={k}
              label={v}
              disabled={disabled}
            />
          </div>
        ))
      }
    </div>
  );
};

FormRadioGroup.propTypes = {
  name: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool
};
