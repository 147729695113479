import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDown, ArrowUp, FaqBtaIcon } from '../../assets';
import { Colors, Fonts } from '../../constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop : '10px'
  },
  faqItem: {
    borderTop: `2px solid ${Colors.GreyScale5}`,
    fontFamily: Fonts.Roboto,
    paddingTop : '5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  faqHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    margin: '10px 0 0 0'
  },
  faqTitle: {
    fontFamily: Fonts.Roboto,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex'
  },
  faqContent: {
    fontFamily: Fonts.Roboto,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingRight: 60,
    paddingBottom: 5,
    paddingLeft: 50,
    justifyContent: 'left',
    fontSize: 14
  },
  faqTileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  faqIcon: {
    margin: '0 10px 0 0'
  },
  faqDropDownIcon: {
    display: 'flex',
    alignItems: 'center'
  }
};

export const FaqComponent = ({
  title,
  content,
  changeVisibility,
  isOpen
}) => {
  const [ open, setOpen ] = React.useState(isOpen);

  const handleChangeVisibility = () => {
    if (typeof changeVisibility === 'function') {
      changeVisibility();
    }

    setOpen(!open);
  };

  return (
    <div style={styles.container}>
      <div style={styles.faqItem}>
        <div style={styles.faqHeader}>
          <div style={styles.faqTileContainer}>
            <div style={styles.faqIcon}>
              <img src={FaqBtaIcon} alt='Icon' />
            </div>
            <div style={styles.faqTitle}>
              {title}
            </div>
          </div>
          <div style={styles.faqDropDownIcon}>
            <img
              style={{ width: '15px', height: '20px', cursor: 'pointer' }}
              src={isOpen ? ArrowUp : ArrowDown} alt='DropdownClose'
              role='presentation'
              onClick={handleChangeVisibility}
              onKeyDown={handleChangeVisibility}
            />
          </div>
        </div>
        {isOpen &&
          <div style={styles.faqContent}>
            {content}
          </div>
        }
      </div>
    </div>
  );
};

FaqComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  changeVisibility: PropTypes.func,
  isOpen: PropTypes.bool
};
