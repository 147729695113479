import localforage from 'localforage';

const valueSuffix = 'v';
const expirySuffix = 'e';

const getItemValue = async (key) => {

  let res = null;

  try {

    res = await localforage.getItem(`${key}:${valueSuffix}`);
  } catch {
    console.error(`Failed to fetch storage item value with key ${key}`);
    throw new Error('StorageGetItemValue');
  }

  return res;
};

const getItemExpiry = async (key) => {

  let res = null;

  try {

    res = await localforage.getItem(`${key}:${expirySuffix}`);
  } catch {
    console.error(`Failed to fetch storage item expiry with key ${key}`);
    throw new Error('StorageGetItemExpiry');
  }

  return res;
};

const getItem = async (key) => {

  let res = null;

  try {

    const value = await getItemValue(key);
    const expiry = await getItemExpiry(key);

    if (!isNaN(expiry) && (+expiry >= new Date().getTime() || +expiry <= 0)) {

      res = value;
    }
  } catch {
    console.error(`Failed to fetch storage item with key ${key}`);
    throw new Error('StorageGetItem');
  }

  return res;
};

const setItem = async (key, value, ttlMs = 0) => {

  let success = false;

  const exp = !isNaN(ttlMs) && +ttlMs > 0 ? new Date().getTime() + ttlMs : 0;

  try {
    await localforage.setItem(`${key}:${valueSuffix}`, value);
    await localforage.setItem(`${key}:${expirySuffix}`, exp);

    success = true;
  } catch {
    console.error(`Failed to store storage item with key ${key}`);
    throw new Error('StorageSetItem');
  }

  return success;
};

const clear = async () => {
  return await localforage.clear();
};

const storage = {
  getItemValue,
  getItemExpiry,
  getItem,
  setItem,
  clear
};

export default storage;
