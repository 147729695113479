import { atom } from 'recoil';
import { useRecoilState, useResetRecoilState } from 'recoil';

export const loaderState = atom({
  key: 'loaderState',
  default: {
    isOpen: false
  }
});

export const useLoader = () => {
  const [loader, setLoader] = useRecoilState(loaderState);
  const resetModal = useResetRecoilState(loaderState);

  const openLoader = () => {
    setLoader({
      isOpen: true
    });
  };

  const closeLoader = () => {
    resetModal();
  };

  return [openLoader, closeLoader, loader];
};
