import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationRoutes, StaticRoutes } from '../../Router';
import { updateFundingDetails } from '../../api/vodaLend';
import { Header, NewFooter, ProductContainer } from '../../components';
import { Colors, Fonts } from '../../constants';
import { fundingDetailsState } from '../../recoil/fundingDetailApplication';
import { productSubmit } from '../../recoil/products';

const styles = {
  main: {
    textAlign: 'center'
  },
  header: {
    backgroundColor: Colors.GreyScale1,
    height: '100%',
    display: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.GreyScale13
  },
  h1: {
    fontFamily: Fonts.Roboto,
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '36px',
    letterSpacing: '-3%',
    textAlign: 'center'

  },
  h2: {
    fontFamily: Fonts.Roboto
  },
  btn_userInfo: {
    fontSize: '14px',
    fontFamily: Fonts.Roboto,
    cursor: 'pointer'
  },
  productBlock: {
    display: 'flex',
    'flex-flow': 'wrap',
    'margin-left': '50px'
  }
};

export const ProductCatalogue = () => {

  const navigate = useNavigate();
  const fetchedProductList = useRecoilValue(productSubmit);

  const fetchedFundingDetails = useRecoilValue(fundingDetailsState);
  const FundingDetails = {
    productId: fetchedFundingDetails?.productId || null,
    productCode: fetchedFundingDetails?.productCode || null,
    partnerCode: fetchedFundingDetails?.partnerCode || null
  };

  const [selectedProduct, setSelectedProduct] = useState(FundingDetails.productId ? FundingDetails : {});

  const backClick = () => {
    navigate(StaticRoutes.FundingApplicationDetails);
  };

  const updateSelectedProduct = (product) => {
    setSelectedProduct(product);
  };

  const handleNext = async () => {
    const fundingDetailResponse = await updateFundingDetails(selectedProduct);
    if (fundingDetailResponse.status === StatusCodes.CREATED) {
      window.location.pathname = ApplicationRoutes.personalDetails;
    }
  };

  return (
    <>
      <Header />
      <div style={styles.main}>
        <header style={styles.header}>
          <h1 style={styles.h1}>{'Choose a funding option for your business goals'}</h1>

          <div style={styles.productBlock}>
            {fetchedProductList?.length > 0 && fetchedProductList?.map(products => (
              <ProductContainer key={products.productId}
                updateSelectedProduct={updateSelectedProduct}
                isSelected={selectedProduct.productId === products.productId}
                {...products} />
            ))}
          </div>

          <NewFooter
            leftTitle={'Back'}
            rightTitle='Next'
            leftCallback={backClick}
            rightCallback={selectedProduct.productId ? () => handleNext() : null}
          />
        </header>
      </div>
    </>
  );
};
