import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => {
  const response = await vodaLend.getPersonalDetails();
  return response.data;
};

const updateState = async (inputData) => {
  const response = await vodaLend.setPersonalDetails(inputData);
  return response.data;
};

const personalDetailsDefault = selector({
  key: 'personalDetailsDefault',
  get: async () => await fetchState()
});

export const personalDetailsState = atom({
  key: 'personalDetailsState',
  default: personalDetailsDefault
});

const formatPayload = (inputData) => {
  return {
    'positionInCompany': inputData.positionInCompany,
    'title': inputData.title || '',
    'idType': inputData.idType,
    'passportNumber': inputData.passportNumber || '',
    'idNumber': inputData.idNumber || '',
    'residentialStatus': inputData.residentialStatus || '',
    'whenMovedIn': inputData.whenMovedIn,
    'passportCountry': inputData.passportCountry,
    'address': {
      'street': inputData.houseStreet || inputData.flatStreet || inputData.townStreet || inputData.officeStreet || '',
      'suburb': inputData.suburb || '',
      'city': inputData.city || '',
      'province': inputData.provinces || '',
      'postalCode': inputData.postalCode || '',
      'country': inputData.country || '',
      'buildingType': inputData.buildingType || '',
      'unitNumber': +inputData.houseUnitNumber || +inputData.flatUnitNumber || +inputData.townUnitNumber || +inputData.officeUnitNumber || '',
      'building': inputData.flatBuilding || inputData.townBuilding || inputData.officeBuilding || ''
    }
  };
};

export const personalDetailsSubmit = selector({
  key: 'personalDetailsSubmit',
  get: async ({ get }) => await get(personalDetailsState),
  set: ({ get, set, reset }, inputData) => {

    if (!inputData) {
      reset(personalDetailsState);
      return;
    }

    const formatedInputData = formatPayload(inputData);
    const cur = get(personalDetailsState) || {};
    if (formatedInputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...formatedInputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(personalDetailsState, data);
      return;
    }

    set(personalDetailsState, updateState({
      ...cur,
      ...formatedInputData
    }));
  }
});
