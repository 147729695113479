import {
  FormikInput
} from '@vfs-digital-channels/ns-react-components';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './FormikInput.module.css';

export const FormikInputField = (
  {
    type,
    name,
    label,
    focus,
    top,
    bottom,
    subtext,
    disabled
  }
) => {
  const containerStyle = type === 'small' ? styles.smallContainer : styles.largeContainer;
  return (
    <div className={containerStyle}>
      <FormikInput
        type={type}
        name={name}
        label={label}
        focus={focus}
        top={top}
        bottom={bottom}
        subtext={subtext}
        disabled={disabled}
      />
    </div>
  );
};

FormikInputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  focus: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  subtext: PropTypes.string,
  disabled: PropTypes.bool
};