export const AnnualBusinessIncome = [
  {
    'label': 'R0 - R499 999',
    'value': 'R499 999'
  },
  {
    'label': 'R500 000 - R999 999',
    'value': 'R999 999'
  },
  {
    'label': 'R1 000 000 - R1 999 999',
    'value': 'R1 999 999'
  },
  {
    'label': 'R2 000 000 - R4 999 999',
    'value': ' R4 999 999'
  },
  {
    'label': 'R5 000 000 - R9 999 999',
    'value': 'R9 999 999'
  },
  {
    'label': 'R10 000 000 - R20 000 000',
    'value': 'R20 000 000'
  },
  {
    'label': 'R20 000 001',
    'value': 'R20 000 001'
  }
];