import React from 'react';
import PropTypes from 'prop-types';
import styles from './anchor.module.css';

export function Anchor({href, title, target = '_self', children, className,onClick}, ...props) {
  return (
    <a href={href}
      title={title}
      target={target}
      className={`${styles.anchor} ${className}`}
      { ...props }
      onClick={onClick}
    >
      { children }
    </a>
  );
}

Anchor.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self']),
  onClick: PropTypes.func
};
