import {
  Button,
  FormikInput,
  FormikSelect,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext
} from 'react-router-dom';
import {
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { StaticRoutes } from '../../../Router';
import { FormSubmitHelper, SupportPopup } from '../../../components';
import {
  FormContainer,
  FormField,
  FormFieldWrapper,
  LoanReasonOptions,
  RepayMonths
} from '../../../constants';
import { mapValues } from '../../../helpers';
import {
  businessDetailsSubmit
} from '../../../recoil';

import { submitApplication } from '../../../api/vodaLend';
import { useLoader } from '../../../components/Loader/useLoader';
import { useModal } from '../../../components/modalController/useModal';
import { findFirstValidationError } from '../../../helpers/formik';
import { useTealium } from '../../../tealium/useTealium';
import styles from './FundingDetails.module.css';

const FIVEMILLION = 5000000;
const TENTHOUSAND = 10000;

const validationSchema = yup.object({
  amountNeed: yup.number().integer('Amount needed must be a whole numerical value').test('amountNeed', 'Amount must be a numerical value between R10k and R5m', (data) => parseInt(data) >= TENTHOUSAND && parseInt(data) <= FIVEMILLION).required('How much do you need is required'),
  repayMonth: yup.mixed().test('repayMonth', 'How long do you need to repay is required', (item) => !!item?.value),
  needFor: yup.mixed().test('needFor', 'What do you need it for is required', (item) => !!item?.value)
});

const fundingInfo = {
  amountNeed: '',
  repayMonth: '',
  needFor: ''
};

export const FundingDetails = () => {

  const [openModal, closeModal] = useModal();
  const [openLoader, closeLoader] = useLoader();
  const { submitState, setSubmitState } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const businessInfo = useRecoilValue(businessDetailsSubmit);
  const { track, events } = useTealium();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.fundingDetails.formStart());
  };

  useEffect(() => {
    track(events.fundingDetails.pageView());
  }, []);

  const goToSubmitPage = () => {
    navigate(StaticRoutes.submit);
  };

  const submitDetails = async (formValues) => {
    const values = {
      loanProductId: '001',
      loanAmount: formValues.amountNeed,
      loanTerm: formValues.repayMonth,
      reason: formValues.needFor?.label,
      monthsInBusiness: businessInfo.timeInBusiness,
      turnOver: businessInfo.revenueBracket,
      businessType: businessInfo.businessType
    };
    track(events.fundingDetails.formEnd(values.loanAmount, values.loanTerm?.label, values.reason));

    try {
      openLoader();
      await submitApplication(mapValues(values));
    } catch {
      openModal({
        title: 'Error',
        children: <div>There was an error submitting your application.</div>,
        type: 'error',
        actions: [
          <Button
            className={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
            key="SubmitApplicationFailed"
            onClick={() => closeModal()}>
            Close
          </Button>
        ]
      });
    } finally {
      closeLoader();
    }

    goToSubmitPage();

  };

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>
      <>
        <div className={styles.titleContainer}>
          <div className={styles.titleHeading}>
            {'Funding details'}
          </div>
          <div className={styles.titleSubHeading}>
            {'Tell us more about your funding \n needs'}
          </div>
        </div>
        <ThemeProvider theme={theme.light}>
          <Formik
            initialValues={fundingInfo}
            onSubmit={submitDetails}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {({ errors, touched }) => {
              handleFormikTouchedErrors(touched, errors);
              return (
                <Form style={FormContainer} onFocus={handleFormFocus}>

                  <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                  <div style={FormFieldWrapper}>
                    <div style={FormField}>

                      <FormikInput label='How much do you need?' name='amountNeed' type='number' />
                    </div>
                    <div style={FormField}>

                      <FormikSelect
                        label='How long do you need to repay?'
                        name='repayMonth'
                        options={RepayMonths}
                        scrollToTop={false} />
                    </div>
                    <div style={FormField}>

                      <FormikSelect
                        label='What do you need it for?'
                        name='needFor'
                        options={LoanReasonOptions}
                        scrollToTop={false} />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ThemeProvider>
        <SupportPopup />
      </>
    </div>
  );
};
