import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import { StaticRoutes } from '../../../Router';
import { getAuthorization } from '../../../api/vodaLend';
import {
  Footer,
  PersonalDetailsComponent,
  personalDetailsValidationSchema
} from '../../../components';
import {
  Colors,
  Fonts,
  FormContainer,
  Gender,
  Position,
  Titles
} from '../../../constants';
import {
  CountryListInput,
  mapValues,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import {
  authDetail,
  personalDetailsState,
  personalDetailsSubmit,
  toasts,
  useIsMobile,
  useShowToast
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 100
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '57px 0 23px 0',
    width: '100%'
  },
  titleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium,
    fontStyle: 'normal',
    fontSize: '1.500em',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  formContainer: {
    ...FormContainer,
    margin: '0 0 100px 0'
  }
};

export const ProfilePersonalDetails = () => {

  const details = useRecoilValue(personalDetailsSubmit);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();
  const showToast = useShowToast();
  const isMobile = useRecoilValue(useIsMobile);
  const resetToast = useResetRecoilState(toasts);
  const reset = useResetRecoilState(personalDetailsState);
  const { track, events } = useTealium();

  useFindErrorOnState([personalDetailsSubmit], () => {
    reset();
  });

  useEffect(() => {
    if (details.error) {
      resetToast();
      return;
    }
  }, [details]);

  useEffect(() => {
    track(events.myProfile.pageView());
  }, []);

  const formDetails = useMemo(() => (details ? {
    ...details,
    idType: details.idType || 'id',
    title: Titles.find((t) => t.value === details.title),
    positionInCompany: Position.find((p) => p.value === details.positionInCompany),
    gender: Gender.find((g) => g.value === details.gender),
    passportNumber: details.passportNumber || '',
    passportCountry: CountryListInput.find((c) => c.value === (details.passportCountry || 'ZA'))
  } : {}), [details]);

  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(personalDetailsSubmit, data);
    return await snapshot.getPromise(personalDetailsSubmit);
  }, []);

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = async (formValues) => {
    const submission = formatFormData(formValues);
    const data = await detailsPromise(submission);
    await updateNames(submission);
    showToast('Updated successfully');
    return data;
  };

  const setTokenData = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(authDetail, data);
    return await snapshot.getPromise(authDetail);
  }, []);

  const updateNames = async(submittedData) =>{
    let tokenData = await getAuthorization();
    const mapTokenData = {
      ...tokenData,
      firstName: submittedData.firstName,
      lastName: submittedData.surname
    };
    setTokenData(mapTokenData);
  };

  const goToMainPage = () => {
    track(events.myProfile.cancelProfileUiInteraction());
    navigate(StaticRoutes.profile);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      track(events.myProfile.saveProfileUiInteraction());
      formRef.current.handleSubmit();
    }
  };

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <div style={styles.titleHeading}>
          Personal details
        </div>
      </div>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validationSchema={personalDetailsValidationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={styles.formContainer}>

                <PersonalDetailsComponent
                  namePrefix=''
                  showDob={true}
                  showGender={true}
                  showIdentity={true}
                  phoneEmailEditable={false}
                />

                <Footer leftCallback={goToMainPage} rightCallback={Object.values(errors).length ? null : handleSubmit} leftTitle={isMobile ? '' : 'Cancel'} rightTitle={'Save'} />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>

    </div>
  );
};
