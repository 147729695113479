import PropTypes from 'prop-types';
import React from 'react';
import styles from './productContainer.module.css';

const styleRef = {
  container: {
    'display': 'table-cell',
    'whiteSpace': 'pre-line',
    'margin': '20px',
    'border': '1px solid',
    'width': '430px',
    'padding-top': '25px',
    'padding-bottom': '25px',
    'padding-left': '20px',
    'padding-right': '20px',
    'cursor': 'pointer'
  }
};

export const ProductContainer = ({
  isSelected,
  updateSelectedProduct,
  productName,
  productId,
  productCode,
  partnerCode,
  productLabel,
  productDescription,
  businessSize,
  annualTurnover,
  tradingHistory,
  maxFundingAmount,
  minFundingAmount,
  minRepaymentTerm,
  maxRepaymentTerm,
  resourceUrl
}) => {

  const handleClick = () => {
    if (!isSelected) {
      updateSelectedProduct({
        productId,
        productCode,
        partnerCode
      });
    } else {
      updateSelectedProduct({});
    }
  };

  const displayRepaymentTerms = () => {
    if (minRepaymentTerm && maxRepaymentTerm) {
      return `${minRepaymentTerm} - ${maxRepaymentTerm}`;
    } else if (minRepaymentTerm) {
      return `${minRepaymentTerm}`;
    } else if (maxRepaymentTerm) {
      return `${maxRepaymentTerm}`;
    } else {
      return '';
    }
  };

  const displayFundingAmount = () => {
    if (minFundingAmount && maxFundingAmount) {
      return `${minFundingAmount} - ${maxFundingAmount}`;
    } else if (minFundingAmount) {
      return `${minFundingAmount}`;
    } else if (maxFundingAmount) {
      return `${maxFundingAmount}`;
    } else {
      return '';
    }
  };

  const makeBold = (item, keyword) => {
    var re = new RegExp(keyword, 'g');
    return (
      item.replace(re, `<span style="font-family:'RobotoBold';font-weight: 600;">${keyword }</span>`)
    );
  };

  return (
    <div style={styleRef.container}
      className={isSelected ? styles.cardSelected : styles.card} onClick={handleClick}>
      <img className={styles.imageStyle}
        src={resourceUrl}
        alt={productName}
        role='presentation'
      />
      <br />
      <div>
        <div><span className={styles.span} >{productLabel?.toUpperCase()}</span></div>
        <div><span className={styles.title} >{productName}</span></div>
      </div>
      <div className={styles.desc}> <span className={styles.description}> {productDescription} </span>
      </div>
      <div className={styles.details}>
        <table className={styles.table}>
          <tr className={styles.tr}>
            <td className={styles.tableTitles}>Business size:</td>
            <td>{businessSize}</td>
          </tr>

          <tr className={styles.tr}>
            <td className={styles.tableTitles}>Turnover:</td>
            <td dangerouslySetInnerHTML={{__html: makeBold(annualTurnover, 'or')}} />
          </tr>

          <tr className={styles.tr}>
            <td className={styles.tableTitles}>Trading history:</td>
            <td>{tradingHistory}</td>
          </tr>

          <tr className={styles.tr}>
            <td className={styles.tableTitles}>Funding limit:</td>
            <td> {displayFundingAmount()} </td>
          </tr>

          <tr>
            <td className={styles.tableTitles}>Repayment term:</td>
            <td> {displayRepaymentTerms()} </td>
          </tr>

        </table>
      </div>
    </div>
  );
};

ProductContainer.propTypes = {
  isSelected: PropTypes.bool,
  updateSelectedProduct: PropTypes.object,
  productName: PropTypes.string,
  partnerCode: PropTypes.string,
  productId: PropTypes.number,
  productLabel: PropTypes.string,
  productCode: PropTypes.string,
  productDescription: PropTypes.string,
  businessSize: PropTypes.string,
  annualTurnover: PropTypes.number,
  tradingHistory: PropTypes.string,
  maxFundingAmount: PropTypes.number,
  minFundingAmount: PropTypes.number,
  minRepaymentTerm: PropTypes.string,
  maxRepaymentTerm: PropTypes.string,
  resourceUrl: PropTypes.string
};