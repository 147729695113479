import {
  FormikSelect,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Field,
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import {
  AddressDetailsComponent,
  FormSubmitHelper,
  SupportPopup,
  addressValidationSchema
} from '../../../components';
import { DateSelector } from '../../../components/DateSelector/DateSelector';
import {
  CommonHeight,
  CustomFormField,
  FormContainer,
  FormField,
  FormFieldError,
  FormFieldWrapper,
  Provinces,
  ResidentialStatus,
  submissionStates
} from '../../../constants';
import {
  CountryListInput,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { personalDetailsSubmit } from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';
import styles from './PersonalAddress.module.css';

const validationSchema = yup.object({
  address: addressValidationSchema,
  residentialStatus: yup.mixed().test('residentialStatus', 'Residential Status is required', (item) => !!item?.value),
  whenMovedIn: yup.date().max(new Date(), 'Date should be in past').required('Current Residence Time is required')
});

export const PersonalAddress = () => {
  const details = useRecoilValue(personalDetailsSubmit);
  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(personalDetailsSubmit, data);
    return await snapshot.getPromise(personalDetailsSubmit);
  }, []);
  const { submitState, setSubmitState } = useOutletContext();
  const errorInSubmittedDetails = useFindErrorOnState([personalDetailsSubmit]);
  const formDetails = useMemo(() => ({
    ...details,
    address: {
      ...details.address,
      province: Provinces.find((p) => p.value === details.address.province),
      country: CountryListInput.find((c) => c.value === (details.address.country || 'ZA'))
    },
    residentialStatus: ResidentialStatus.find((r) => r.value === details.residentialStatus)
  }), [details]);
  const location = useLocation();
  const { track, events } = useTealium();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.personalAddress.formStart());
  };

  const formatFormData = (formValues) => stripEmpty({
    ...formValues,
    address: Object.keys(formValues.address).reduce((accum, key) => ({
      ...accum,
      [key]: formValues.address[key]?.value || formValues.address[key]
    }), {}),
    residentialStatus: formValues.residentialStatus.value,
    whenMovedIn: formValues.whenMovedIn
  });

  const submitHandler = async (formValues) => {
    track(events.personalAddress.formEnd());
    const formatData = formatFormData(formValues);

    return detailsPromise(formatData);
  };

  useEffect(() => {
    if (submitState === submissionStates.submitting && !errorInSubmittedDetails?.contents) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [submitState, errorInSubmittedDetails]);

  useEffect(() => {
    track(events.personalAddress.pageView());
  }, []);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };
  return (
    <div className={styles.container} style={{
      minHeight: CommonHeight.MinPageHeightWithHeaderWithoutFooter,
      margin: `0 0 ${CommonHeight.NavigationFooterHeightDesktop} 0`
    }}>
      <div className={styles.titleContainer}>
        <div className={styles.titleHeading}>
          Tell us about your address
        </div>
        <div className={styles.titleSubHeading}>
          Please provide the below details about your personal address
        </div>
      </div>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>

                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <div style={FormFieldWrapper}>

                  <AddressDetailsComponent />

                  <div style={FormField}>

                    <FormikSelect
                      label='Residential Status'
                      name='residentialStatus'
                      options={ResidentialStatus}
                      scrollToTop={false}
                    />
                  </div>

                  <div style={errors?.whenMovedIn ? FormFieldError : CustomFormField}>
                    <Field
                      name='whenMovedIn'
                      component={DateSelector}
                      label='When did you move to this address?'
                    />
                  </div>
                </div>

              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
