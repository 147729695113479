import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledUTag = styled.u`
  position: relative;
`;

const StyledSpan= styled.span`
    color: var(--color-red-100);
    cursor: pointer;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
`;

const TextLink = ({linkText, ...props}) => {
  return (
    <>
      <StyledSpan
        onClick={props.onClick}
        onKeyDown={props.onClick}
        role='presentation'>
        <StyledUTag>
          {linkText}
        </StyledUTag>
      </StyledSpan>
    </>
  );
};

TextLink.propTypes = {
  linkText: PropTypes.string,
  onClick: PropTypes.func
};

export default TextLink;
