import {
  Position,
  Titles,
  Provinces,
  ResidentialStatus,
  buildingType,
  IdTypes
} from '../../../constants';
import { CountryListInput } from '../../../helpers/CountryListInput';
import { FieldTypes } from '../../../components/FormBuilder';

export const formInitialValues = {
  passportNumber: '',
  title: '',
  positionInCompany: '',
  residentialStatusValue: '',
  buildingType: '',
  estateName: '',
  unitNumber: '',
  street: '',
  suburb: '',
  postalCode: '',
  identityType: '',
  provinces: '',
  moveInDate: '',
  country: '',
  address: ''
};

export const identityType = {
  placeholder: 'Identity Type',
  name: 'idType',
  options: IdTypes,
  scrollToTop: false
};

export const passportNumber = {
  type: 'text',
  inputMode: 'text',
  name: 'passportNumber',
  placeholder: 'Passport number',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const title = {
  placeholder: 'Title',
  name: 'title',
  options: Titles,
  scrollToTop: false
};

export const positionInCompany = {
  placeholder: 'Position in company',
  name: 'positionInCompany',
  options: Position,
  scrollToTop: false
};

export const residentialStatusValue = {
  placeholder: 'Residential status',
  name: 'residentialStatus',
  options: ResidentialStatus,
  scrollToTop: false
};

export const moveInDate = {
  showOnlyMonthYear: false,
  label: 'When did you move to this address?',
  name: 'whenMovedIn'
};

export const buildingTypes = {
  placeholder: 'Choose building type',
  name: 'buildingType',
  options: buildingType,
  scrollToTop: false
};

export const estateName = {
  placeholder: 'Estate Name',
  name: 'estateName',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true

};

export const unitNumber = {
  placeholder: 'Unit or house number',
  name: 'unitNumber',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const streetName = {
  placeholder: 'Street Name',
  name: 'street',
  type: 'text',
  inputMode: 'text',
  hint: 'Example: 12 Ridge Road',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const suburb = {
  placeholder: 'Suburb',
  name: 'suburb',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const postalCode = {
  placeholder: 'Postal Code',
  name: 'postalCode',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const countries = {
  placeholder: 'South Africa',
  name: 'country',
  options: [...CountryListInput],
  scrollToTop: false,
  disabled: true
};

export const provinces = {
  placeholder: 'Province',
  name: 'provinces',
  options: Provinces,
  scrollToTop: false
};

export const address = {
  placeholder: 'Address',
  name: 'street',
  type: 'text',
  inputMode: 'text',
  hint: 'Example: 12 Ridge Road',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const idTypeFields = {
  id: [{
    type: FieldTypes.INPUT,
    props: {
      type: 'text',
      inputMode: 'text',
      name: 'idNumber',
      placeholder: 'South African ID number',
      disabled: false,
      dark: false,
      hideUnderline: true
    }
  }],
  pp: [{
    type: FieldTypes.INPUT,
    props: {
      type: 'text',
      inputMode: 'text',
      name: 'passportNumber',
      placeholder: 'Passport number',
      disabled: false,
      dark: false,
      hideUnderline: true
    }
  },
  {
    type: FieldTypes.SELECT,
    props: {
      placeholder: 'country of issue',
      name: 'passportCountry',
      options: [...CountryListInput],
      scrollToTop: false
    }
  }]
};

export const buildingTypeFields = {
  House: [
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'houseUnitNumber',
            placeholder: 'Enter unit or house number',
            type: 'number',
            inputMode: 'numeric',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'houseStreet',
            placeholder: 'Enter street name',
            type: 'text',
            inputMode: 'text',
            disabled: false,
            hint: 'Example: 12 Ridge Road',
            dark: false,
            hideUnderline: true
          }
        }
      ]
    }
  ],
  'Flat/Apartment': [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'flatBuilding',
        placeholder: 'Enter building name',
        type: 'text',
        inputMode: 'text',
        disabled: false,
        dark: false,
        hideUnderline: true
      }
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'flatUnitNumber',
            placeholder: 'Enter flat/unit/floor',
            type: 'number',
            inputMode: 'numeric',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            placeholder: 'Address',
            name: 'flatStreet',
            type: 'text',
            inputMode: 'text',
            hint: 'Example: 12 Ridge Road',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        }
      ]
    }
  ],
  'Townhouse/Security Estate': [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'townBuilding',
        placeholder: 'Enter estate name',
        type: 'text',
        inputMode: 'text',
        disabled: false,
        dark: false,
        hideUnderline: true
      }
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'townUnitNumber',
            placeholder: 'Enter unit or house number',
            type: 'number',
            inputMode: 'numeric',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'townStreet',
            placeholder: 'Enter street name',
            type: 'text',
            inputMode: 'text',
            hint: 'Example: 12 Ridge Road',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        }
      ]
    }
  ],
  Office: [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'officeBuilding',
        placeholder: 'Enter building or office park name',
        type: 'text',
        inputMode: 'text',
        disabled: false,
        dark: false,
        hideUnderline: true
      }
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'officeUnitNumber',
            placeholder: 'Enter unit or suite number',
            type: 'number',
            inputMode: 'numeric',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            placeholder: 'Address',
            name: 'officeStreet',
            type: 'text',
            hint: 'Example: 12 Ridge Road',
            inputMode: 'text',
            disabled: false,
            dark: false,
            hideUnderline: true
          }
        }
      ]
    }
  ]
};

export const formFields = [
  {
    type: FieldTypes.SELECT,
    props: identityType
  },
  {
    type: FieldTypes.DYNAMIC_ID_FIELDS,
    fields: idTypeFields
  },
  {
    type: FieldTypes.SELECT,
    props: title
  },
  {
    type: FieldTypes.SELECT,
    props: positionInCompany
  },
  {
    type: FieldTypes.SELECT,
    props: residentialStatusValue
  },
  {
    type: FieldTypes.DATESELECTOR,
    props: moveInDate
  },
  {
    type: FieldTypes.LABEL,
    label: 'What is your residential address?'
  },
  {
    type: FieldTypes.SELECT,
    props: buildingTypes
  },
  {
    type: FieldTypes.DYNAMIC_BUILDING_FIELDS,
    fields: buildingTypeFields
  },
  {
    type: FieldTypes.PAIRED_FIELDS,
    fields: [
      {
        type: FieldTypes.INPUT,
        props: suburb
      },
      {
        type: FieldTypes.INPUT,
        props: postalCode
      }
    ]
  },
  {
    type: FieldTypes.PAIRED_FIELDS,
    fields: [
      {
        type: FieldTypes.SELECT,
        props: provinces
      },
      {
        type: FieldTypes.SELECT,
        props: countries
      }
    ]
  }
];
