import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '../chip/chip';

const statusMap = {
  'submitted': {text: 'Submitted', color: 'purple'},
  'in-assessment': {text: 'In Assessment', color: 'orange'},
  'quote-available': {text: 'Quote Available', color: 'blue'},
  'active': {text: 'Active', color: 'green'},
  'closed': {text: 'Closed', color: 'black'},
  'inactive': {text: 'Inactive', color: 'black'},
  'pending': {text: 'Pending', color: 'yellow'},
  'declined': {text: 'Declined', color: 'red'},
  'paused': {text: 'Paused', color: 'black'}
};

export const StatusChip = ({status}) => {
  return (
    <Chip color={ statusMap[status]?.color }>
      { statusMap[status]?.text }
    </Chip>
  );
};

StatusChip.propTypes = {
  status: PropTypes.string
};