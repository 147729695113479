import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '../baseModal/baseModal';
import styles from './modal.module.css';
import {
  RedCrossIcon,
  InfoIconBlueBackground,
  SuccessIcon,
  WarningIcon
} from '../../assets';

const modalTypesIcons = {
  info: InfoIconBlueBackground,
  warning: WarningIcon,
  error: RedCrossIcon,
  success: SuccessIcon
};

export const Modal = ({ type, title, children, onClose, actions = [], onCloseModal }) => {

  const icon = modalTypesIcons[type];

  const handleClose = () => {
    if (typeof onCloseModal === 'function') {
      onCloseModal();
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <BaseModal className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.close} onClick={handleClose} onKeyDown={handleClose} role="presentation">
          <svg width="30" height="30" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 30.4688L30 10.4688" stroke="#1A1029" strokeWidth="3" strokeLinecap="round"
              strokeLinejoin="round" />
            <path d="M30 30.4688L10 10.4688" stroke="#1A1029" strokeWidth="3" strokeLinecap="round"
              strokeLinejoin="round" />
          </svg>
        </div>

        {icon &&
          <div className={`${styles.icon}`}>
            <img src={icon} alt={type} className={styles.imgIcon} />
          </div>
        }

        {title && (
          <div className={styles.title}>{title}</div>
        )}
      </div>

      {children &&
        <div className={styles.body}>{children}</div>
      }

      {actions.length > 0 &&
        <div className={styles.footer}>
          <div className={styles.divider} />
          <div className={styles.actions}>
            {actions}
          </div>
        </div>
      }
    </BaseModal>
  );
};

Modal.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onCloseModal: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.node)
};
