import {
  DropDown
} from '@vfs-digital-channels/ns-react-components-new';
import styled from 'styled-components';

export const FormikSelectField = styled(DropDown)`
 width: 100%;
  height: 60px;
  margin-bottom: 10px;
  & > :nth-child(1) {
    background-color: var(--color-white);
    border-color: var(--color-grey-200);
    width: 100%;
    height: 60px;
    & > :nth-child(4) {
      display: none;
    }
    & > :nth-child(5) {
      display: none;
    }
  }
`;