import React, {
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  ArrowUp,
  CloseIcon,
  Logo
} from '../../assets';
import { Colors } from '../../constants';
import { useRecoilValue } from 'recoil';
import { userFirstName } from '../../recoil';
import { NavBar } from '../NavBar/NavBar';
import { HamburgerIcon } from '../../assets';

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 80,
    background: Colors.GreyScale1,
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.15)',
    position: 'sticky',
    top: 0,
    zIndex: 99
  },
  leftSection: {
    flexGrow: 0,
    width: 27,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4%',
    justifyContent: 'flex-start'
  },
  backImage: {
    width: 20,
    height: 16,
    transform: 'rotate(270deg)'
  },
  logoImage: {
    width: '100%'
  },
  rightSection: {
    flexGrow: 0,
    display: 'flex',
    marginRight: '5%',
    alignItems: 'center',
    height: 24,
    width: 24
  },
  sideNavMobile: {
    height: '100%',
    background: Colors.GreyScale1,
    width: 343,
    zIndex: 101,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    right: 0
  },
  backgroundBlurred: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: '#00000040',
    zIndex: 100,
    top: 0
  },
  closeImg: {
    marginLeft: 'auto',
    paddingTop: 20,
    paddingRight: 20
  }
};

const MobileLeftSection = ({ onBackClick }) => {

  return onBackClick
    ? <div
      style={styles.leftSection}
      onClick={onBackClick || undefined}
      onKeyDown={onBackClick || undefined}
      role='button'
      tabIndex='0'
    >
      <img src={ArrowUp} alt='back button' style={styles.backImage} />
    </div>
    : <div style={styles.leftSection}>
      <img style={styles.logoImage} src={Logo} alt='Vodacom logo' />
    </div>
  ;
};

MobileLeftSection.propTypes = {
  onBackClick: PropTypes.func
};

const MobileRightSection = ({ onMenuClick }) => {
  return (
    <div style={styles.rightSection}>
      <img src={HamburgerIcon} alt="copy" onClick={onMenuClick} />
    </div>
  );
};

MobileRightSection.propTypes = {
  onMenuClick: PropTypes.func
};

const MobileSideNav = ({ onCloseClick }) => (
  <>
    <div style={styles.sideNavMobile}>
      <img
        src={CloseIcon}
        alt="Close Icon"
        style={styles.closeImg}
        role='presentation'
        onClick={() => { onCloseClick?.(); }}
        onKeyDown={() => { onCloseClick?.(); }}
      />
      <NavBar />
    </div>
    <div
      style={styles.backgroundBlurred}
      onClick={() => { onCloseClick?.(); }}
      onKeyDown={() => { onCloseClick?.(); }}
      role='presentation'
    />
  </>
);

MobileSideNav.propTypes = {
  onCloseClick: PropTypes.func
};

export const MobileHeader = ({ children, onBackClick }) => {
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const name = useRecoilValue(userFirstName);

  const headerContainerStyle = useMemo(() => ({
    ...styles.headerContainer,
    pointerEvents: isSideNavOpen ? 'none' : 'auto'
  }), [isSideNavOpen]);

  return isSideNavOpen ? (
    <MobileSideNav onCloseClick={() => setSideNavOpen(false)} />
  ) : (
    <div style={headerContainerStyle}>
      <MobileLeftSection onBackClick={onBackClick} />

      {children}

      { name && <MobileRightSection onMenuClick={() => setSideNavOpen(true)} /> }
    </div>
  );
};

MobileHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onBackClick: PropTypes.func
};
