import {
  FormikInput,
  FormikSearchInput,
  FormikSelect,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Field,
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { StaticRoutes } from '../../../Router';
import {
  Footer,
  SquircleCheckBox,
  SupportPopup
} from '../../../components';
import { DateSelector } from '../../../components/DateSelector/DateSelector';
import {
  BusinessType,
  Colors,
  Fonts,
  FormContainer,
  FormField,
  FormFieldError,
  FormFieldWrapper,
  IndustryTypes
} from '../../../constants';
import { mapValues, replaceUndefined, stripEmpty } from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { isValidDate, isValidRevenue } from '../../../helpers/strings';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import {
  businessDetailsState,
  businessDetailsSubmit,
  toasts,
  useIsMobile,
  useShowToast
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';
import {
  businessFinancialValidationSchema
} from '../../LoanApplication';
import {
  businessDetailsValidationSchema
} from '../../LoanApplication/BusinessDetails/businessSchema';

const validationSchema = yup.object({}).concat(businessDetailsValidationSchema).concat(businessFinancialValidationSchema);

export const ProfileBusinessDetails = () => {

  const details = useRecoilValue(businessDetailsSubmit);
  const formRef = React.useRef();
  const isMobile = useRecoilValue(useIsMobile);
  const navigate = useNavigate();
  const location = useLocation();
  const showToast = useShowToast();
  const resetToast = useResetRecoilState(toasts);
  const reset = useResetRecoilState(businessDetailsState);
  useFindErrorOnState([businessDetailsSubmit], () => {
    reset();
  });
  const { track, events } = useTealium();

  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    tradingAs: details.tradingAs || '',
    businessType: BusinessType.find((b) => b.value === details.businessType),
    differentBusinessName: !!details.tradingAs,
    industry: IndustryTypes.find((i) => i.value === details.industry),
    timeInBusiness: isValidDate(details.timeInBusiness) ? details.timeInBusiness : '',
    revenueBracket: isValidRevenue(details.revenueBracket) ? details.revenueBracket : ''
  }), [details]);

  const goToMainPage = () => {
    navigate(StaticRoutes.profile);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(businessDetailsSubmit, data);
    return await snapshot.getPromise(businessDetailsSubmit);
  }, []);

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = async (formValues) => {
    const submission = formatFormData(formValues);
    await detailsPromise(submission);
    showToast('Updated successfully');
  };

  useEffect(() => {
    if (details.error) {

      resetToast();
      return;
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (

    <div style={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ values, errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer}>

                <div style={isMobile ? styles.mobileTitleContainer : styles.titleContainer}>
                  <div style={isMobile ? styles.mobileTitleHeading : styles.titleHeading}>
                    Tell us about your business
                  </div>
                  <div style={styles.titleSubHeading}>
                    Please provide the below details about your business
                  </div>
                </div>

                <div style={FormFieldWrapper}>
                  <div style={FormField}>
                    <FormikSelect
                      label='Type of business'
                      name='businessType'
                      options={BusinessType}
                      scrollToTop={false} />
                  </div>

                  <div style={FormField}>
                    <FormikInput label='Business name' name='name' />
                  </div>

                  <div style={styles.formFieldRadioContainer}>
                    <Field
                      name='differentBusinessName'
                      id='differentBusinessName_BusinessDetails'
                      label='My business is known by a different name'
                      component={SquircleCheckBox}
                      checked={values.differentBusinessName} />
                  </div>

                  {values.differentBusinessName && (
                    <div style={FormField}>
                      <FormikInput label='Trading Name (Optional)' name='tradingAs' />
                    </div>
                  )}

                  {values.businessType?.value !== 'Sole Proprietorship' && (
                    <div style={FormField}>
                      <FormikInput label='Business registration number' name='regNumber' />
                    </div>
                  )}

                  {values.businessType?.value === 'Sole Proprietorship' && (
                    <div style={FormField}>
                      <FormikInput label='VAT number' name='vatNumber' />
                    </div>
                  )}

                  <div style={{
                    ...FormField,
                    ...styles.subTextMargin
                  }}>

                    <FormikSearchInput
                      label='What industry are you in? (Search for a business category)'
                      name='industry'
                      subtext='Example: Restaurant, Bed & Breakfast, car hire etc'
                      options={IndustryTypes}
                    />
                  </div>

                  {/* Business Financials */}

                  <div style={
                    errors?.timeInBusiness && touched?.timeInBusiness
                      ? FormFieldError
                      : { ...FormField, ...(isMobile ? {} : { marginBottom: '10px' }) }
                  }>
                    <Field
                      label='Time in business (in years)'
                      name='timeInBusiness'
                      showOnlyMonthYear = {true}
                      component={DateSelector}
                    />
                  </div>

                  <div style={FormField}>
                    <FormikInput label='Annual revenue (in Rands)' name='revenueBracket' />
                  </div>

                  <div style={FormField}>

                    <FormikInput
                      label='Landline number'
                      name='landline' />
                  </div>

                  <div style={FormField}>

                    <FormikInput
                      label='Website (Optional)'
                      name='website' />
                  </div>
                </div>

                <Footer
                  leftCallback={goToMainPage}
                  rightCallback={Object.values(errors).length ? null : handleSubmit}
                  leftTitle={isMobile ? '' : 'Cancel'}
                  rightTitle={'Save'}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 110
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '60px 0 10px 0',
    width: '100%'
  },
  mobileTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 30px 0 30px'
  },
  titleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.Roboto,
    fontStyle: 'normal',
    fontSize: '32px',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    margin: '0 0 20px 0',
    width: 'inherit'
  },
  mobileTitleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium,
    fontStyle: 'normal',
    fontSize: '22px',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    margin: '0 0 20px 0',
    width: 'inherit'
  },
  titleSubHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.Roboto,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.GreyScale10,
    width: 'inherit',
    whiteSpace: 'pre-line'
  },
  formFieldRadioContainer: {
    display: 'flex',
    justifyContent: 'left',
    margin: '12px 0 10px 0',
    width: '32.6rem',
    maxWidth: '100%'
  },
  subTextMargin: {
    marginBottom: '20px'
  }
};
