export const ApplicationSteps = {
  register: {
    title: 'Get started',
    stepNum: 1
  },
  personalDetails: {
    paths: [
      '/apply/personalDetails'
      // '/apply/personalAddress'
    ],
    title: 'Personal Details',
    stepNum: 2
  },
  businessDetails: {
    paths: [
      '/apply/businessDetails',
      '/apply/businessFinancial',
      '/apply/businessAddress',
      '/apply/businessDirectors'
    ],
    title: 'Business Details',
    stepNum: 3
  },
  financialDetails: {
    paths: [
      '/apply/documentDetails',
      '/apply/financialDetails'
    ],
    title: 'Financial Details',
    stepNum: 4
  },
  fundingDetails: {
    paths: ['/apply/fundingDetails'],
    title: 'Funding Details',
    stepNum: 5
  }
};
