import {
  atom,
  atomFamily,
  selector,
  selectorFamily
} from 'recoil';
import * as vodaLend from '../api/vodaLend';
const Buffer = require('buffer/').Buffer;

const fetchList = async () => await vodaLend.listDocuments();

const uploadRefresh = async (file, docType, docName) => {
  await vodaLend.uploadDocument(file, docType, docName);
  return await fetchList();
};

const deleteFile = async (docId) => await vodaLend.deleteDocument(docId);

const fetchFile = async (docId) => {
  if (!docId) {
    return null;
  }
  const data = await vodaLend.fetchDocument(docId);
  if (data?.error) {
    return data;
  }
  if (!data?.FileContent) {
    return { error: { message: 'Something went wrong, please try again' } };
  }
  let response;
  try {

    const decoded = Buffer.from(data.FileContent, 'base64');
    const blob = new Blob([decoded], { type: 'application/octet-stream' });
    const blobUrl = URL.createObjectURL(blob);

    response = {
      blobFile: blobUrl,
      fileName: data.FileName
    };

  } catch {
    response = { error: { message: 'Something went wrong, please try again' } };
  }
  return response;
};

const documentsListInit = selector({
  key: 'documentsListInit',
  get: async () => await vodaLend.listDocuments()
});

export const documentsListState = atom({
  key: 'documentsListState',
  default: documentsListInit
});

export const singleDocFetchState = atom({
  key: 'singleDocFetchState',
  default: null
});

export const documentsByType = selectorFamily({
  key: 'documentsByType',
  get: (docType) => ({ get }) => (get(documentsListState) || []).filter((f) => f.docType === docType)
});

export const documentData = atomFamily({
  key: 'documentData',
  default: null
});

export const documentSync = selectorFamily({
  key: 'documentSync',
  get: () => (_) => null,
  set: ({ docName, docType }) => ({ set }, file) => set(documentsListState, uploadRefresh(file, docType, docName))
});

export const documentDelete = selector({
  key: 'documentDelete',
  get: ({ get }) => get(documentsListState),
  set: ({ set }, docId) => set(documentsListState, deleteFile(docId))
});

export const fetchDoc = selector({
  key: 'fetchDoc',
  get: ({ get }) => get(singleDocFetchState),
  set: ({ set }, docId) => set(singleDocFetchState, fetchFile(docId))
});