import { onlyYearMonthRegex, revenueRegex } from '../constants/validation';

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const SEPARATE_INTVL = 3;
const DECIMAL_LENGTH = 2;
const BYTES = 1024;

export const formatCurrency = (amnt, forceDecimal = false) => {
  const whole = Math.trunc(Math.abs(amnt));
  const wholeStr = `${whole}`;
  const cent = (Math.abs(amnt) - whole).toFixed(DECIMAL_LENGTH).substring(1);
  const dec = forceDecimal || cent !== '.00' ? `${cent}` : '';
  const separated = wholeStr.split('').map((v, i) => i > 0 && (wholeStr.length - i) % SEPARATE_INTVL === 0 ? `,${v}` : v).join('');
  return amnt < 0 ? `-R ${separated}${dec}` : `R ${separated}${dec}`;
};

export const formatCurrencyWithSpace = (amnt, forceDecimal = false) => {
  const whole = Math.trunc(Math.abs(amnt));
  const wholeStr = `${whole}`;
  const cent = (Math.abs(amnt) - whole).toFixed(DECIMAL_LENGTH).substring(1);
  const dec = forceDecimal || cent !== '.00' ? `${cent}` : '';
  const separated = wholeStr.split('').map((v, i) => i > 0 && (wholeStr.length - i) % SEPARATE_INTVL === 0 ? ` ${v}` : v).join('');
  return amnt < 0 ? `-R ${separated}${dec}` : `R ${separated}${dec}`;
};

export const formatBytes = (bytes) => {
  const sizes = ['B', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(BYTES));
  return `${parseFloat((bytes / Math.pow(BYTES, i)).toFixed(0))} ${sizes[i] || ''}`;
};

export const isValidDate = (date) => {
  return onlyYearMonthRegex.test(date);
};

export const convertCurrencyToAmount = (currency) => {
  return +currency?.substring(1).replace(/\s/g, '');
};

export const convertDateValueToDateSelectorFormat = (date) => {
  return `${date?.slice(0, 4)}-${date?.slice(4)}`;
};

export const convertDateSelectorToDateValueFormat = (dateValue) => {
  return dateValue?.replace('-', '');
};

export const isValidRevenue = (revenue) => {
  return revenueRegex.test(revenue);
};
