import React, {
  useMemo
} from 'react';
import {
  Colors,
  Fonts
} from '../../constants';
import PropTypes from 'prop-types';
import {
  Button
} from '@vfs-digital-channels/ns-react-components';
import {
  useIsMobile
} from '../../recoil';
import {
  useRecoilValue
} from 'recoil';

const FOOTER_HEIGHT = 80;
const FOOTER_MOBILE_HEIGHT = 77;

const styles = {
  FooterContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: FOOTER_HEIGHT,
    background: Colors.GreyScale11,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    left: 0,
    fontFamily: Fonts.Roboto
  },
  footerContainerMobile: {
    height: FOOTER_MOBILE_HEIGHT,
    justifyContent: 'center'
  },
  footerContainerFlexEnd: {
    justifyContent: 'flex-end'
  },
  back: {
    marginLeft: 40,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: 150,
    color: Colors.GreyScale1,
    border: `2px solid ${Colors.GreyScale7}`,
    borderRadius: 8,
    justifyContent: 'center',
    background: 'transparent'
  },
  next: {
    display: 'flex',
    alignItems: 'center',
    width: 180,
    height: 40,
    color: Colors.GreyScale1,
    marginRight: 40,
    justifyContent: 'center',
    borderRadius: 8
  },
  nextMobile: {
    width: '95%',
    marginRight: 0,
    height: 44
  },
  hideElement: {
    display: 'none'
  }
};

export const FormFooter = ({
  onNextClick,
  onBackClick,
  btnDisabled,
  hideBackBtn,
  hideNextBtn,
  rightBtnLabel,
  leftBtnLabel
}) => {

  const isMobile = useRecoilValue(useIsMobile);
  const nextBtn = useMemo(() => nextBtnStyle(isMobile, hideNextBtn, btnDisabled), [isMobile, hideNextBtn, btnDisabled]);
  const footerContainer = useMemo(() => footerContainerStyle(isMobile, hideBackBtn), [isMobile, hideBackBtn]);

  return (
    <div style={footerContainer}>
      <Button
        style={{ ...styles.back, ...(hideBackBtn ? styles.hideElement : {}) }}
        as={'button'}
        onClick={onBackClick}
        onKeyDown={onBackClick}
      >
        {leftBtnLabel || 'Back'}
      </Button>

      <Button
        role='presentation'
        style={nextBtn}
        onClick={onNextClick}
        onKeyDown={onNextClick}
        as={'button'}
        type='submit'
        disabled={btnDisabled}
      >
        {rightBtnLabel || 'Next'}
      </Button>
    </div>
  );
};

FormFooter.propTypes = {
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  btnDisabled: PropTypes.bool,
  hideBackBtn: PropTypes.bool,
  hideNextBtn: PropTypes.bool,
  rightBtnLabel: PropTypes.string,
  leftBtnLabel: PropTypes.string
};

const nextBtnStyle = (isMobile, hideNextBtn, btnDisabled) => {
  let style;
  if (hideNextBtn) {
    return {
      ...styles.next,
      ...styles.hideElement
    };
  } else if (isMobile) {
    style = {
      ...styles.next,
      ...styles.nextMobile
    };
  } else {
    style = styles.next;
  }
  if (btnDisabled) {
    style = {
      ...style,
      background: 'transparent'
    };
  }
  return style;
};

const footerContainerStyle = (isMobile, hideBackBtn) => {
  if (isMobile && hideBackBtn) {
    return {
      ...styles.FooterContainer,
      ...styles.footerContainerMobile
    };
  } else if (hideBackBtn && !isMobile) {
    return {
      ...styles.FooterContainer,
      ...styles.footerContainerFlexEnd
    };
  }
  return styles.FooterContainer;
};