import React from 'react';

import PropTypes from 'prop-types';

import {
  Colors
} from '../../constants';

const styles = {
  hamburgerMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative'
  },
  hamburgerLine: {
    width: '100%',
    height: '12%',
    background: Colors.GreyScale10,
    borderRadius: '10% / 50%'
  }
};

export const HamburgerMenu = ({ onClick }) => (
  <div
    style={styles.hamburgerMenu}
    role='button'
    onClick={() => onClick?.()}
    onKeyDown={() => onClick?.()}
    tabIndex='0'
  >
    <div style={styles.hamburgerLine} />
    <div style={styles.hamburgerLine} />
    <div style={styles.hamburgerLine} />
  </div>
);

HamburgerMenu.propTypes = {
  onClick: PropTypes.func
};