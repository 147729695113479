import React, {
  useState
} from 'react';
import {
  AccordionStyle,
  Colors,
  FaqBta,
  Fonts
} from '../../../constants';
import {
  Accordion,
  FaqComponent,
  Footer
} from '../../../components';
import { BTAAccountSummary } from '../../../components';
import { useRecoilValue } from 'recoil';
import {
  btaTransactionHistory,
  useIsMobile
} from '../../../recoil';
import { ArrowDown } from '../../../assets';
import { formatCurrency } from '../../../helpers';
import { Table } from '../../../components/table/table';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 60,
    marginBottom: 20,
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    width: '95%'
  },
  titleHeading: {
    display: 'flex',
    fontFamily: Fonts.RobotoBold,
    fontSize: 30,
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  summaryAccordion: {
    display: 'flex',
    width: '95%'
  },
  transactionHistoryAccordion: {
    display: 'flex',
    width: '95%'
  },
  faqContainerOuter: {
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${Colors.GreyScale5}`,
    borderRadius: 8,
    paddingTop: 15,
    paddingBottom: 20,
    width: '95%',
    marginBottom: 80
  },
  faqContainerInner: {
    width: '95%',
    height: '100%'
  },
  faqHeading: {
    fontFamily: Fonts.RobotoMedium,
    fontSize: 18,
    lineHeight: '24px',
    display: 'flex',
    marginBottom: 10
  },
  accordionStyle: {
    borderRadius: 5,
    border: `1px solid ${Colors.GreyScale5}`,
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30
  },
  accordionTitle: {
    ...AccordionStyle.accordionTitle,
    fontSize: 24
  },
  tableContainer: {
    width: '90%',
    display: 'flex'
  }
};

export const HeaderValues = {
  displayDate: 'Date',
  capital: 'Capital',
  cost: 'Cost',
  paymentAmount: 'Amount',
  paidAmount: 'Paid amount', arrears: 'Arrears', earlySettlement: 'Early Settlement', paidDate: 'Paid date', repaymentState: 'Status'
};

export const BTAAccount = () => {
  const [openSection, setOpenSection] = useState('summary');
  const transactionHistoryDetails = useRecoilValue(btaTransactionHistory);
  const isMobile = useRecoilValue(useIsMobile);
  const accordionStyleFn = (data) => ({ ...(isMobile ? { ...data, borderRadius: 20 } : data) });
  const accordionTitleStyle = isMobile ? { ...styles.accordionTitle, fontSize: 18 } : styles.accordionTitle;
  const isDataPresent = Array.isArray(transactionHistoryDetails.records) && transactionHistoryDetails.records.length > 0;

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <div style={isMobile ? { ...styles.titleHeading, fontSize: 22.5, justifyContent: 'center' } : styles.titleHeading}>
          Business Term Advance
        </div>
      </div>

      {/* Account Summary*/}
      <div style={styles.summaryAccordion}>
        <Accordion
          headerStyle={{ width: '95%' }}
          isOpen={openSection === 'summary'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={accordionTitleStyle}>
                  {'Account Summary'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'summary' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt='DropdownOpen'
                  role='presentation'
                  onClick={() => setOpenSection(openSection === 'summary' ? '' : 'summary')}
                  onKeyDown={() => setOpenSection(openSection === 'summary' ? '' : 'summary')}
                />
              </div>
            </>
          }
          containerStyle={accordionStyleFn(styles.accordionStyle)}
        >
          <div style={AccordionStyle.accordionChildren}>
            <BTAAccountSummary />
          </div>
        </Accordion>
      </div>

      {/* Transaction History*/}
      <div style={styles.transactionHistoryAccordion}>
        <Accordion
          containerStyle={accordionStyleFn(styles.accordionStyle)}
          headerStyle={{ width: '95%' }}
          isOpen={openSection === 'history'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={accordionTitleStyle}>
                  {'Transaction history'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'history' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt='DropdownOpen'
                  role='presentation'
                  onClick={() => setOpenSection(openSection === 'history' ? '' : 'history')}
                  onKeyDown={() => setOpenSection(openSection === 'history' ? '' : 'history')}
                />
              </div>
            </>
          }
        >
          <div style={AccordionStyle.accordionChildren}>
            <div style={styles.tableContainer}>
              {isDataPresent &&
                <Table
                  data={
                    transactionHistoryDetails.records.map((d) => ({
                      ...d,
                      cost: formatCurrency(d.cost),
                      capital: formatCurrency(d.capital),
                      paymentAmount: formatCurrency(d.paymentAmount),
                      paidAmount: formatCurrency(d.paidAmount),
                      arrears: formatCurrency(d.arrears),
                      earlySettlement: formatCurrency(d.earlySettlement),
                      paidDate: d.paidDate
                    }))
                  }
                  heading={HeaderValues}
                  footer={{
                    cost: formatCurrency(transactionHistoryDetails.total.cost),
                    capital: formatCurrency(transactionHistoryDetails.total.capital),
                    paymentAmount: formatCurrency(transactionHistoryDetails.total.paymentAmount),
                    paidAmount: formatCurrency(transactionHistoryDetails.total.paidAmount),
                    arrears: formatCurrency(transactionHistoryDetails.total.arrears),
                    earlySettlement: formatCurrency(transactionHistoryDetails.total.earlySettlement),
                    displayDate: 'TOTAL'
                  }}
                />
              }
              {!isDataPresent &&
                'No Transaction History Found'
              }
            </div>
          </div>
        </Accordion>
      </div>

      {/* FAQ */}
      <div style={accordionStyleFn(styles.faqContainerOuter)}>
        <div style={styles.faqContainerInner}>
          <div style={styles.faqHeading}>
            FAQ&apos;s
          </div>
          {FaqBta.map((item) => {
            const key = `faqSec${item.title}`;
            return (<FaqComponent
              key={key}
              title={item.title}
              content={item.content}
              changeVisibility={() => setOpenSection(openSection === key ? '' : key)}
              isOpen={openSection === key}
            />);
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};
