import {
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { StaticRoutes } from '../../../Router';
import {
  AddressDetailsComponent,
  Footer,
  addressValidationSchema
} from '../../../components';
import {
  Colors,
  Fonts,
  FormContainer,
  FormFieldWrapper,
  Provinces
} from '../../../constants';
import {
  CountryListInput,
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import {
  businessDetailsState,
  businessDetailsSubmit,
  toasts,
  useIsMobile,
  useShowToast
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 40
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '57px 0 23px 0',
    width: '100%'
  },
  titleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium,
    fontStyle: 'normal',
    fontSize: '1.500em',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  formContainer: {
    ...FormContainer,
    margin: '0 0 100px 0'
  }
};

const validationSchema = yup.object({
  address: addressValidationSchema
});

export const ProfileBusinessAddress = () => {

  const details = useRecoilValue(businessDetailsSubmit);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();
  const showToast = useShowToast();
  const isMobile = useRecoilValue(useIsMobile);
  const resetToast = useResetRecoilState(toasts);
  const reset = useResetRecoilState(businessDetailsState);
  useFindErrorOnState([businessDetailsSubmit], () => {
    reset();
  });
  const { track, events } = useTealium();

  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    address: details.address && {
      ...details.address,
      province: Provinces.find((p) => p.value === details.address.province),
      country: CountryListInput.find((c) => c.value === 'ZA')
    }
  }), [details]);

  const detailsPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(businessDetailsSubmit, data);
    return await snapshot.getPromise(businessDetailsSubmit);
  }, []);

  const formatFormData = (formValues) => mapValues(stripEmpty({
    ...formValues,
    address: formValues.address && {
      ...formValues.address,
      country: formValues.address.country.label
    }
  }));

  const formSubmit = async (formValues) => {

    const submission = formatFormData(formValues);
    const data = await detailsPromise(submission);
    showToast('Updated successfully');
    return data;
  };

  const goToMainPage = () => {
    navigate(StaticRoutes.profile);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (details.error) {

      resetToast();
      return;
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <div style={styles.titleHeading}>
          Business address details
        </div>
      </div>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={styles.formContainer}>

                <div style={FormFieldWrapper}>

                  <AddressDetailsComponent />

                </div>

                <Footer leftCallback={goToMainPage} rightCallback={Object.values(errors).length ? null : handleSubmit} leftTitle={isMobile ? '' : 'Cancel'} rightTitle={'Save'} />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>

    </div>
  );
};