import { Provinces } from '../../../constants/Provinces';
import { CountryListInput } from '../../../helpers/CountryListInput';
import { FieldTypes } from '../../../components/FormBuilder';

export const formInitialValues = {
  regNumber: '',
  buildingType: '',
  unitNumber: '',
  streetName: '',
  suburb: '',
  postalCode: '',
  province: '',
  category: '',
  name: '',
  vatNumber: '',
  landline: '',
  website: '',
  tradingAs: '',
  country: '',
  building: ''
};

export const registrationNumber = {
  type: 'text',
  inputMode: 'text',
  placeholder: 'Business registration number',
  name: 'regNumber',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const buildingTypes = {
  placeholder: 'Choose building type',
  name: 'buildingType',
  options: [
    { value: 'House', label: 'House' },
    { value: 'Flat/Apartment', label: 'Flat/Apartment' },
    { value: 'Townhouse/Security Estate', label: 'Townhouse/Security Estate' },
    { value: 'Office', label: 'Office' }],
  scrollToTop: false
};

export const unitNumber = {
  placeholder: 'Unit or house number',
  name: 'unitNumber',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const streetName = {
  placeholder: 'Street Name',
  name: 'streetName',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const suburb = {
  placeholder: 'suburb',
  name: 'suburb',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const postalCode = {
  placeholder: 'Postal code',
  name: 'postalCode',
  type: 'text',
  inputMode: 'text',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const province = {
  placeholder: 'Province',
  name: 'province',
  options: [...Provinces],
  scrollToTop: false
};

export const countryField = {
  placeholder: 'South Africa',
  name: 'country',
  options: [...CountryListInput],
  scrollToTop: false
};

export const name = {
  type: 'text',
  inputMode: 'text',
  placeholder: 'Business name',
  name: 'name',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const vatNumber = {
  type: 'text',
  inputMode: 'text',
  name: 'vatNumber',
  placeholder: 'VAT number',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const phoneNumber = {
  type: 'text',
  inputMode: 'text',
  name: 'landline',
  placeholder: 'Business phone number',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const website = {
  type: 'text',
  inputMode: 'text',
  name: 'website',
  placeholder: 'Website (Optional)',
  disabled: false,
  dark: false,
  hideUnderline: true
};

export const category = {
  placeholder: 'Choose a category',
  name: 'industry',
  options: [
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Construction & Engineering', label: 'Construction & Engineering' },
    { value: 'IT & Communications', label: 'IT & Communications' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Security', label: 'Security' },
    { value: 'Transport & Logistics', label: 'Transport & Logistics' },
    { value: 'Wholesale & Distribution', label: 'Wholesale & Distribution' },
    { value: 'Other', label: 'Other' }
  ],
  scrollToTop: false
};
export const tradingAs = '';
export const differentBusinessNameCheckbox = {
  name: 'differentBusinessName',
  label: 'My business is known by a different name',
  variant: 'secondary',
  size: 'md',
  shape: 'square',
  disabled: false
};

export const isPersonalAddressCheckbox = {
  name: 'isPersonalAddress',
  label: 'My business and my residential address are the same',
  variant: 'secondary',
  size: 'md',
  shape: 'square',
  disabled: false
};

export const buildingTypeFields = {
  House: [
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'unitNumber',
            label: 'Unit or house number',
            placeholder: 'Enter unit or house number'
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'streetName',
            label: 'Street Name',
            placeholder: 'Enter street name'
          }
        }
      ]
    }
  ],
  'Flat/Apartment': [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'building',
        label: 'Building name',
        placeholder: 'Enter building name'
      }
    },
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'flatUnitFloor',
        label: 'Flat/unit/floor',
        placeholder: 'Enter flat/unit/floor'
      }
    },
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'address',
        label: 'Address',
        placeholder: 'Enter address'
      }
    }
  ],
  'Townhouse/Security Estate': [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'estateName',
        label: 'Estate name',
        placeholder: 'Enter estate name'
      }
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'unitNumber',
            label: 'Unit or house number',
            placeholder: 'Enter unit or house number'
          }
        },
        {
          type: FieldTypes.INPUT,
          props: {
            name: 'streetName',
            label: 'Street Name',
            placeholder: 'Enter street name'
          }
        }
      ]
    }
  ],
  Office: [
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'buildingOfficeParkName',
        label: 'Building or Office Park name',
        placeholder: 'Enter building or office park name'
      }
    },
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'unitSuiteNumber',
        label: 'Unit or suite number',
        placeholder: 'Enter unit or suite number'
      }
    },
    {
      type: FieldTypes.INPUT,
      props: {
        name: 'address',
        label: 'Address',
        placeholder: 'Enter address'
      }
    }
  ]
};

export const getFormFields = (businessType) => {
  const fields = [
    {
      type: FieldTypes.INPUT,
      props: name
    },
    {
      type: FieldTypes.CHECKBOX_WITH_INPUT,
      checkbox: differentBusinessNameCheckbox,
      conditionalInput: {
        name: 'tradingAs',
        label: 'Business trading name',
        placeholder: 'Enter business trading name'
      }
    },
    ...(businessType !== 'Sole Proprietorship'
      ? [
        {
          type: FieldTypes.INPUT,
          props: registrationNumber
        }
      ]
      : (businessType === 'Sole Proprietorship')
        ? [
          {
            type: FieldTypes.INPUT,
            props: vatNumber
          }
        ]
        : []),

    {
      type: FieldTypes.CATEGORY_WITH_HEADING,
      label: 'What category describes your business best?'
    },
    {
      type: FieldTypes.SELECT,
      props: category
    },
    {
      type: FieldTypes.INPUT,
      props: phoneNumber
    },
    {
      type: FieldTypes.INPUT,
      props: website
    },
    {
      type: FieldTypes.CHECKBOX,
      props: isPersonalAddressCheckbox
    },
    {
      type: FieldTypes.CUSTOM_SELECT,
      props: buildingTypes
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.INPUT,
          props: suburb
        },
        {
          type: FieldTypes.INPUT,
          props: postalCode
        }
      ]
    },
    {
      type: FieldTypes.PAIRED_FIELDS,
      fields: [
        {
          type: FieldTypes.CUSTOM_SELECT,
          props: province
        },
        {
          type: FieldTypes.CUSTOM_SELECT,
          props: countryField
        }
      ]
    },
    {
      type: FieldTypes.DYNAMIC_BUILDING_FIELDS,
      fields: buildingTypeFields
    }
  ];

  return fields;
};
