import { Button } from '@vfs-digital-channels/ns-react-components';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { StaticRoutes } from '../../../Router';
import { acceptOffers } from '../../../api/vodaLend';
import {
  ArrowDown
} from '../../../assets';
import {
  Accordion,
  ErrorModalSection,
  Footer,
  TableComponent
} from '../../../components';
import { useLoader } from '../../../components/Loader/useLoader';
import { useModal } from '../../../components/modalController/useModal';
import {
  AccordionStyle,
  Colors,
  CommonDetails,
  Fonts,
  appStatuses
} from '../../../constants';
import { formatCurrency } from '../../../helpers';
import { withVerification } from '../../../helpers/withVerification';
import {
  applicationsList,
  offer,
  offerRepaymentSched,
  useIsMobile,
  userEmail
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    margin: '20px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0 30px 0',
    width: '100%'
  },
  titleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoBold,
    fontSize: '28px',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  descriptionTitle: {
    display: 'flex',
    justifyContent: 'left',
    fontFamily: Fonts.RobotoBold,
    fontSize: '1em',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  descriptionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    fontFamily: Fonts.Roboto,
    fontSize: '1em',
    lineHeight: '25px',
    color: Colors.GreyScale12,
    width: '90%',
    margin: '5px 0 20px 0',
    paddingLeft: '20px'
  },
  fundingAccordion: {
    display: 'flex',
    width: '95%'
  },
  fundingDetailContainer: {
    ...AccordionStyle.accordionChildren,
    display: 'flex',
    flexDirection: 'column',
    width: '94%'
  },
  fundingDetailsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  fundingDetailsInfoOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: Colors.GreyScale2
  },
  fundingDetailsTitle: {
    display: 'flex',
    fontFamily: Fonts.RobotoMedium,
    fontSize: '1em',
    lineHeight: '15px',
    color: Colors.GreyScale12,
    margin: '10px'
  },
  fundingDetailsValue: {
    display: 'flex',
    fontFamily: Fonts.RobotoMedium,
    fontSize: '1em',
    lineHeight: '15px',
    color: Colors.GreyScale12,
    margin: '10px'
  },
  terms: {
    color: Colors.GreyScale10,
    fontSize: 14,
    fontFamily: Fonts.Roboto,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 100,
    width: '95%'
  },
  termsLink: {
    color: Colors.Primary4,
    fontSize: 14,
    fontFamily: Fonts.Roboto,
    cursor: 'pointer',
    marginLeft: 2
  },
  accordionStyle: {
    borderRadius: 5,
    border: `1px solid ${Colors.GreyScale5}`,
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 30
  }
};

const QuoteAccordion = ({ isOpen, toggleOpen }) => {

  const navigate = useNavigate();
  const offerDetail = useRecoilValue(offer);
  const isMobile = useRecoilValue(useIsMobile);
  const accordionStyleFn = (data) => ({ ...(isMobile ? { ...data, borderRadius: 20 } : data) });
  const fundingDetailsInfoOdd = isMobile ? { ...styles.fundingDetailsInfoOdd, flexDirection: 'column' } : styles.fundingDetailsInfoOdd;
  const fundingDetailsInfo = isMobile ? { ...styles.fundingDetailsInfo, flexDirection: 'column' } : styles.fundingDetailsInfo;

  useEffect(() => {
    if (!offerDetail) {
      navigate(StaticRoutes.applications);
    }
  }, [offerDetail]);

  return (
    <Accordion
      isOpen={isOpen}
      headerStyle={{ width: isMobile ? '90%' : '100%' }}
      headerChildren={
        <>
          <div style={AccordionStyle.accordionHeaderLeft}>
            <div style={AccordionStyle.accordionTitle}>
              Funding Details
            </div>
          </div>

          <div>
            <img
              style={{ ...AccordionStyle.ArrowImg, rotate: isOpen ? '180deg' : '' }}
              src={ArrowDown}
              alt='DropdownOpen'
              role='presentation'
              onClick={() => toggleOpen()}
              onKeyDown={() => toggleOpen()}
            />
          </div>
        </>
      }
      containerStyle={accordionStyleFn(styles.accordionStyle)}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={isMobile ? { ...styles.fundingDetailContainer, width: '90%' } : styles.fundingDetailContainer}>
          {offerDetail && (
            <>
              <div style={fundingDetailsInfoOdd}>
                <div style={styles.fundingDetailsTitle}>Advance amount</div>
                <div style={styles.fundingDetailsValue}>{formatCurrency(offerDetail.amount)}</div>
              </div>
              <div style={fundingDetailsInfo}>
                <div style={styles.fundingDetailsTitle}>Company name</div>
                <div style={styles.fundingDetailsValue}>{offerDetail.companyName}</div>
              </div>
              <div style={fundingDetailsInfoOdd}>
                <div style={styles.fundingDetailsTitle}>No of months</div>
                <div style={styles.fundingDetailsValue}>{offerDetail.loanTerm}</div>
              </div>
              <div style={fundingDetailsInfo}>
                <div style={styles.fundingDetailsTitle}>Total repayment</div>
                <div style={styles.fundingDetailsValue}>{formatCurrency(offerDetail.totalRepayment)}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Accordion>
  );
};

QuoteAccordion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired
};

const RepaymentHeader = {
  displayDate: 'Date',
  capital: 'Capital',
  cost: 'Cost',
  repayment: 'Repayment Amount'
};

const RepaymentAccordion = ({ isOpen, toggleOpen }) => {
  const offerDetail = useRecoilValue(offer);
  const repaymentDetail = useRecoilValue(offerRepaymentSched);
  const isMobile = useRecoilValue(useIsMobile);
  const accordionStyleFn = (data) => ({ ...(isMobile ? { ...data, borderRadius: 20 } : data) });

  return (
    <Accordion
      isOpen={isOpen}
      headerStyle={{ width: isMobile ? '90%' : '100%' }}
      headerChildren={
        <>
          <div style={AccordionStyle.accordionHeaderLeft}>
            <div style={AccordionStyle.accordionTitle}>
              Expected Repayment Schedule
            </div>
          </div>

          <div>
            <img
              style={{ ...AccordionStyle.ArrowImg, rotate: isOpen ? '180deg' : '' }}
              src={ArrowDown}
              alt='DropdownOpen'
              role='presentation'
              onClick={toggleOpen}
              onKeyDown={toggleOpen}
            />
          </div>
        </>
      }
      containerStyle={accordionStyleFn(styles.accordionStyle)}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={isMobile ? { ...styles.fundingDetailContainer, width: '90%' } : styles.fundingDetailContainer}>
          <ul>
            <li>Repayment of your funding is made {offerDetail?.paymentFrequency} via debit order over {offerDetail?.loanTerm} ({repaymentDetail?.length} payments in total)</li>
            <li>If you choose to settle your advance early you will save on all future costs.</li>
            <li>Please note that we do not charge any processing, initiating or monthly service fees. We only charge the cost as per below, simple!</li>
          </ul>
          {repaymentDetail && (
            <TableComponent
              data={
                repaymentDetail.map(d => ({
                  ...d,
                  capital: formatCurrency(d.capital),
                  cost: formatCurrency(d.cost),
                  repayment: formatCurrency(d.repayment)
                }))
              }
              heading={RepaymentHeader}
            />
          )}
        </div>
      </div>
    </Accordion>
  );
};

RepaymentAccordion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired
};

export const BTAFundingQuote = withVerification('/quote', () => {

  const [openSection, setOpenSection] = useState('funding');
  const email = useRecoilValue(userEmail);
  const navigate = useNavigate();
  const [appsList, setAppList] = useRecoilState(applicationsList);
  const offerDetail = useRecoilValue(offer);
  const [openModal, closeModal] = useModal();
  const [openLoader, closeLoader] = useLoader();
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.fundingQuote.pageView());
  }, []);

  const goToTermsConditions = () => {
    track(events.fundingQuote.termsAndCondition());
    window.open(CommonDetails.vodalendTermsConditions, '_blank');
  };

  const handleQuoteSubmit = async () => {
    track(events.fundingQuote.acceptQuote());
    try {
      openLoader();
      await acceptOffers({ offerId: offerDetail.offerId, acceptedOffer: true });
      track(events.fundingQuote.journeyEnd());
      openModal({
        title: 'Thank you',
        children: <div style={{ display: 'flex', maxWidth: 361, width: '100%', justifyContent: 'center' }}>
          {`We have received your response.\n An email with your contract will be sent to: \n ${email || ''}`}
        </div>,
        type: '',
        onCloseModal: goToNextPage,
        actions: [
          <Button
            style={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
            key="acceptOfferError"
            onClick={goToNextPage}>
            Got it!
          </Button>
        ]
      });

    } catch (error) {
      openModal({
        title: 'Error',
        children: <ErrorModalSection
          traceId={error?.response?.headers?.['x-amzn-trace-id'] || ''}
          error={error}
          onCloseErrorModal={closeModal}
        />,
        type: 'error'
      });
    } finally {
      closeLoader();
    }
  };

  const goToNextPage = () => {

    const updatedApplicationStatus = appsList.map((d) => {
      if (d?.applicationStatus === appStatuses.QuoteAvailable || d?.applicationStatus === appStatuses.AwaitingCustomerAssessment) {
        d = { ...d, applicationStatus: appStatuses.QuoteAccepted };
      }
      return d;
    });

    closeModal();
    setAppList({ applications: updatedApplicationStatus });
    navigate('/applications');
  };

  const repaymentSection = () => {
    track(events.fundingQuote.expectedRepaymentSchedule());
    setOpenSection(openSection === 'repayment' ? '' : 'repayment');
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <div style={styles.titleHeading}>
          Funding quote
        </div>
      </div>

      <div style={styles.descriptionContainer}>
        <div style={styles.descriptionTitle}>How it works</div>
        <ol style={styles.descriptionContent}>
          <li>Please review your funding application below.</li>
          <li>If you are satisfied, please accept the quote.</li>
          <li>We will then email you a copy of the Advance Agreement which includes a surety form and needs to be digitally signed by yourself.</li>
          <li>Funds will be transferred to your bank account as soon as the signed documents have been received.</li>
        </ol>
      </div>

      <div style={styles.fundingAccordion}>
        <QuoteAccordion isOpen={openSection === 'funding'} toggleOpen={() => setOpenSection(openSection === 'funding' ? '' : 'funding')} />
      </div>

      <div style={styles.fundingAccordion}>
        <RepaymentAccordion isOpen={openSection === 'repayment'} toggleOpen={repaymentSection} />
      </div>

      <div style={styles.terms}>
        By accepting you agree to the Business Term Advance & Vodacom
        <span
          style={styles.termsLink}
          onClick={goToTermsConditions}
          onKeyDown={goToTermsConditions}
          role='presentation'
        >
          <u>Terms and conditions</u>
        </span>.
      </div>

      <Footer rightCallback={handleQuoteSubmit} rightTitle={'Accept quote'} />
    </div>
  );
});
