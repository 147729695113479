import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Field,
  Form,
  Formik
} from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { StaticRoutes } from '../../Router';
import { postFundingDetails, updateFundingDetails } from '../../api/vodaLend';
import {
  FormikInputField,
  FormikSelectField,
  Header,
  NewFooter,
  SupportPopup
} from '../../components';
import { useLoader } from '../../components/Loader/useLoader';
import { NewDateSelector } from '../../components/NewDateSelector/NewDateSelector';
import { Typography } from '../../components/typography/typography';
import {
  AnnualBusinessIncome,
  BusinessType,
  FormContainer
} from '../../constants';
import {
  convertCurrencyToAmount,
  convertDateSelectorToDateValueFormat,
  convertDateValueToDateSelectorFormat,
  mapValues,
  replaceUndefined,
  stripEmpty
} from '../../helpers';
import { findFirstValidationError } from '../../helpers/formik';
import { isValidDate } from '../../helpers/strings';
import { fundingDetailsState, fundingDetailsSubmit } from '../../recoil';
import { useTealium } from '../../tealium/useTealium';
import styles from './FundingApplicationDetails.module.css';
import { ValidationSchema } from './ValidationSchema';

export const FundingApplicationDetails = () => {
  const fetchedFundingDetails = useRecoilValue(fundingDetailsState);
  const [fundingDetails, setFundingDetails] = useRecoilState(fundingDetailsSubmit);
  const [openLoader, closeLoader] = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const { track, events } = useTealium();

  const DetailsEntry = () => {
    const formRef = useRef();
    const formDetails = useMemo(() => replaceUndefined({
      ...fundingDetails,
      loanAmount: fundingDetails?.loanAmount || '',
      timeInBusiness : isValidDate(convertDateValueToDateSelectorFormat(fundingDetails.timeInBusiness)) ? convertDateValueToDateSelectorFormat(fundingDetails.timeInBusiness) : '',
      businessType: BusinessType.find((b) => b.value === fundingDetails?.businessType),
      revenue: AnnualBusinessIncome.find((i) => convertCurrencyToAmount(i.value) === fundingDetails?.revenue)
    }), [fundingDetails]);

    const formatFormData = (formValues) => {
      const businessType = formValues.businessType.value;
      const revenue = convertCurrencyToAmount(formValues.revenue.value);
      const timeInBusiness = convertDateSelectorToDateValueFormat(formValues.timeInBusiness);
      const formatedFormData = {...formValues, businessType, revenue, timeInBusiness};
      return mapValues(stripEmpty(formatedFormData));
    };

    const CheckPostRequestType = (fundingDetail) => {
      let checkPostRequestType;
      if(fundingDetail.error) {
        checkPostRequestType= true;
      } else {
        checkPostRequestType = Object.values(fundingDetail).every(x => x === null || x === '');
      }
      return checkPostRequestType;
    };

    const handleSubmit = async (formValues) => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
      let fundingDetailResponse = '';
      openLoader();
      const formatedFormData = formatFormData(formValues);
      setFundingDetails(formatedFormData);
      const isPostRequest = CheckPostRequestType(fetchedFundingDetails);
      if(isPostRequest) {
        fundingDetailResponse = await postFundingDetails(formatedFormData);
        if(fundingDetailResponse.status === StatusCodes.CREATED) {
          navigate(StaticRoutes.Products);
        }
      } else {
        fundingDetailResponse = await updateFundingDetails(formatedFormData);
        if(fundingDetailResponse.status === StatusCodes.CREATED) {
          navigate(StaticRoutes.Products);
        }
      }
      closeLoader();
    };

    const handleFormikTouchedErrors = (touched, errors) => {
      const errorMessage = findFirstValidationError(errors, touched);
      if (!errorMessage) {
        return;
      }
      track(events.error('form', location.pathname, errorMessage));
    };

    const backClick = () => {
      navigate(StaticRoutes.logout);
    };

    return (
      <div>
        <div className={styles.formwrapper}>
          <Typography level={3} family={'medium'} align={'center'} className={styles.titleHeading} spacing={5}>
          Start your funding application
          </Typography>
          <Typography size={'b1'} align={'center'} className={styles.titleSubHeading} >
            {'Please give your details.'}
          </Typography>
        </div>
        <ThemeProvider theme={theme.light} >
          <Formik
            initialValues={formDetails}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
            validateOnMount={true}
            innerRef={formRef}
          >
            {({ values, errors, touched }) => {
              handleFormikTouchedErrors(touched, errors);
              return( <div>
                <Form style={FormContainer}>
                  <FormikInputField label='How much do you need' subtext='Example R 10 000 000 or R 15 000 000' type='number' name='loanAmount' />
                  <FormikSelectField
                    label='How is your business registered?'
                    name={'businessType'}
                    options={BusinessType}
                    scrollToTop={false}
                  />
                  <div className={errors.timeInBusiness ? styles.erroredDateContainer : styles.DateContainer} >
                    <Field
                      name={'timeInBusiness'}
                      component={NewDateSelector}
                      showOnlyMonthYear={true}
                      label='How long have you been in business?'
                    />
                  </div>
                  <FormikSelectField
                    label='What is your annual business income'
                    name={'revenue'}
                    options={AnnualBusinessIncome}
                    scrollToTop={false}
                  />
                  <NewFooter
                    leftTitle={'Back'}
                    rightTitle='Next'
                    leftCallback={backClick}
                    rightCallback={Object.values(errors).length ? null : () => handleSubmit(values, errors)}
                  />
                </Form>
              </div>);
            }}
          </Formik>
        </ThemeProvider>
        <SupportPopup />
      </div>
    );
  };

  return (
    <div >
      <Header />
      <DetailsEntry />
    </div>
  );
};