import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vfs-digital-channels/ns-react-components';

const container = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '5px',
  paddingBottom: '5px',
  justifyContent: 'center',
  marginTop: '25px',
  gap: '5px'
};

const disabledBtnStyle = {
  backgroundColor: '#C9CCD4',
  border: 'none'
};

const leftButtonStyle = {
  color: '#007C92',
  backgroundColor: 'white',
  borderRadius: '0px',
  border: 'none'
};

const rightButtonStyle = {
  borderRadius: '50px',
  backgroundColor: '#007C92'
};

export const NewFooter = ({
  leftTitle,
  rightTitle,
  leftCallback,
  rightCallback,
  rightBtnStyle = {}
}) => {

  const btnRightStyle = useMemo(() => ({ ...(!rightCallback ? { ...rightButtonStyle, ...disabledBtnStyle, ...rightBtnStyle } : rightButtonStyle) }), [rightCallback]);
  return (
    <div style={container} >
      {leftTitle && <Button
        style={leftButtonStyle}
        onClick={leftCallback || undefined}
        disabled={!leftCallback}
        variant='secondary'
      >
        {leftTitle}
      </Button>}

      {!!rightTitle && <Button
        style={btnRightStyle}
        onClick={rightCallback || undefined}
        disabled={!rightCallback}
        variant='primary'
      >
        {rightTitle}
      </Button>}
    </div>
  );
};

NewFooter.propTypes = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  leftCallback: PropTypes.func,
  rightCallback: PropTypes.func,
  rightBtnStyle: PropTypes.object
};