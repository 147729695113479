import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useResetRecoilState, useRecoilValue} from 'recoil';
import { ThemeProvider } from 'styled-components';
import { getAuthorization } from '../../../api/vodaLend';
import { FormSubmitHelper, SupportPopup } from '../../../components';
import {PersonalDetailsSchema} from './PersonalDetailsSchema';
import {
  FormContainer,
  FormField,
  personalDetailsKeys
} from '../../../constants';
import {
  mapValues,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { authDetail, personalDetailsState, personalDetailsSubmit, useIsMobile } from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';
import styles from './PersonalDetails.module.css';
import { FormBuilder } from '../../../components/FormBuilder';
import { formFields } from './PersonalDetailsConstants';

export const PersonalDetails = () => {
  const [details, setPersonalDetails] = useRecoilState(personalDetailsSubmit);
  const { submitState, setSubmitState, backClick, submissionStates, nextClick } = useOutletContext();

  const formRef = React.useRef();
  const reset = useResetRecoilState(personalDetailsState);
  const errorInSubmittedDetails = useFindErrorOnState([personalDetailsSubmit], () => {
    reset();
    setSubmitState(submissionStates.canSubmit);
  });
  const location = useLocation();

  const formDetails = useMemo(() => (details ? {
    passportNumber: details?.passportNumber || '',
    title: details?.title || '',
    positionInCompany: details?.positionInCompany || '',
    residentialStatus: details?.residentialStatus || '',
    idType: details?.idType || '',
    whenMovedIn: details?.whenMovedIn || '',
    postalCode: details?.address?.postalCode || '',
    provinces: details?.address?.province || '',
    idNumber: details?.idNumber || '',
    country: 'South Africa',
    suburb: details?.address?.suburb || '',
    city: details?.address?.city || '',
    passportCountry: details?.passportCountry || '',
    buildingType: details?.address?.buildingType || '',

    houseUnitNumber: details?.address?.unitNumber || '',
    flatUnitNumber: details?.address?.unitNumber || '',
    townUnitNumber: details?.address?.unitNumber || '',
    officeUnitNumber: details?.address?.unitNumber || '',

    flatBuilding: details?.address?.building || '',
    townBuilding: details?.address?.building || '',
    officeBuilding: details?.address?.building || '',

    houseStreet: details?.address?.street || '',
    flatStreet: details?.address?.street || '',
    townStreet: details?.address?.street || '',
    officeStreet: details?.address?.street || ''

  } : {}), [details]);
  const { track, events } = useTealium();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.personalDetails.formStart());
  };
  const isMobile = useRecoilValue(useIsMobile);

  const isDetailsCaptured = (details, personalDetailsKeys) => {
    return personalDetailsKeys.every(key => details[key] !== null && details[key] !== undefined && details[key] !== '');
  };

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = async (formValues) => {
    const identityType = formValues.idType === 'id' ? 'SA ID Number' : 'Passport';
    track(events.personalDetails.formEnd(identityType));
    const submission = formatFormData(formValues);
    setPersonalDetails(submission);
    await updateNames(submission);
    return details;
  };

  const setTokenData = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(authDetail, data);
    return await snapshot.getPromise(authDetail);
  }, []);

  const updateNames = async (submittedData) =>{
    let tokenData = await getAuthorization();
    const mapTokenData = {
      ...tokenData,
      firstName: submittedData.firstName,
      lastName: submittedData.surname
    };
    setTokenData(mapTokenData);
  };

  useEffect(() => {
    track(events.personalDetails.pageView());
  }, []);

  useEffect(() => {
    if (submitState === submissionStates.submitting && !errorInSubmittedDetails?.contents) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [submitState, errorInSubmittedDetails]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }
    track(events.error('form', location.pathname, errorMessage));
  };
  return (
    <div className={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validate={(values) => {
            try {
              PersonalDetailsSchema.validateSync(values, { abortEarly: false });
              return {};
            } catch (error) {
              return error.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
              }, {});
            }
          }}
          validateOnBlur={true}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ values, errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>
                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <FormBuilder
                  fields={formFields}
                  title="Approved business representative details"
                  subtitle={isDetailsCaptured(formDetails, personalDetailsKeys) ? 'Please provide approved business representative information.' : 'Please confirm your personal details as the approved business representative'}
                  isMobile={isMobile}
                  values={values}
                  FormField={FormField}
                  errors={errors}
                  submitState={submitState}
                  setSubmitState={setSubmitState}
                  backClick={backClick}
                  isDetailsCaptured={isDetailsCaptured(formDetails, personalDetailsKeys)}
                  submissionStates={submissionStates}
                  nextClick={nextClick}
                  touched={touched}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
