import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { toastIds, toastItem, toastProperties } from '../../recoil';
import { ToastItem } from './ToastItem';

const TIMEOUTS = {
  default: 3000,
  long: 10000
};

const toastContainerStyle = {
  position: 'fixed',
  width: '100%',
  margin: '20px auto',
  display: 'flex',
  zIndex: 100,
  alignItems: 'center',
  gap: 16,
  flexDirection: 'column'
};

export const Toast = ({ children }) => {

  const toastIdList = useRecoilValue(toastIds);
  const toastPropertiesMap = useRecoilValue(toastProperties);
  const clearToast = useRecoilCallback(({ reset, snapshot }) => async (toastId) => {
    const t = await snapshot.getPromise(toastItem(toastId));
    if (t?.timer) {
      clearTimeout(t.timer);
    }
    reset(toastItem(toastId));
  }, []);
  const showToast = useRecoilCallback(({ set, snapshot }) => async (toastId) => {

    const t = await snapshot.getPromise(toastItem(toastId));
    if (t?.timer) {
      return t.timer;
    }

    const timer = setTimeout(() => { clearToast(toastId); }, t?.timeout ? TIMEOUTS[t?.timeout] : TIMEOUTS.default);
    set(toastItem(toastId), {
      ...(t || {}),
      timer
    });

    return timer;
  }, []);

  useEffect(() => {
    toastIdList.forEach((toastId) => showToast(toastId));
  }, [toastIdList]);

  return (<>
    <div style={toastContainerStyle}>
      {Object.
        keys(toastPropertiesMap)
        .map((toastId) => (
          <ToastItem
            key={toastId}
            text={toastPropertiesMap[toastId].text}
            iconType={toastPropertiesMap[toastId].iconType}
            link={toastPropertiesMap[toastId].link}
            clearToast={() => clearToast(toastId)}
            hasCloseButton={toastPropertiesMap[toastId].hasCloseButton}
          />
        ))
      }
    </div>
    {children}
  </>);
};

Toast.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
