import {
  FormikSelect
} from '@vfs-digital-channels/ns-react-components';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './FormikSelect.module.css';

export const FormikSelectField = ({
  options,
  name,
  label,
  scrollToTop,
  subtext,
  top,
  bottom
}) => {
  return (
    <div className={styles.Container}>
      <FormikSelect
        subtext={subtext}
        top={top}
        bottom={bottom}
        options={options}
        name={name}
        label={label}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

FormikSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  scrollToTop: PropTypes.bool,
  subtext: PropTypes.string,
  top: PropTypes.bool,
  bottom: PropTypes.bool
};