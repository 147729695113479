import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckboxSelected } from '../../assets';

const SquircleDiv = styled.div`
  background: #D9D9D9;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const SquircleInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const SquircleLabel = styled.div`
  margin: 0 0 0 7px;
  display: flex;
  height: 29px;
  align-items: center;
`;

export const SquircleCheckBox = ({
  field,
  form,
  ...props
}) => {
  const [opacity, setOpacity] = useState('');
  useEffect(()=>{
    field.name === 'marketingConsent' && (typeof field.value === 'undefined' || field.value)
      ? setOpacity('1')
      : setOpacity('0');
  },[]);

  return (
    <div>
      { field.name === 'marketingConsent' ?
        <SquircleDiv role='presentation'>
          <SquircleInput
            type="checkbox"
            id={props.id}
            name={field.name}
            checked={field.value}
            value={field.value}
            defaultChecked={true}
            onChange={(e)=>{
              form.setFieldValue(field.name, e.target.checked);
              form.setFieldTouched(field.name);
              form.validateForm(form.values);
              e.target.checked === true ? setOpacity('1') : setOpacity('0');
            }}
          />
          <img style={{ width: '30px', height: '30px', borderRadius: '10px', opacity: opacity }} src={CheckboxSelected} alt='checkbox ticked' />
        </SquircleDiv>
        :<SquircleDiv
          onClick={() => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, !field.value);
          }}
          onKeyDown={() => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, !field.value);
          }}
          role='presentation'>
          <SquircleInput
            type="checkbox"
            id={props.id}
            name={field.name}
            checked={field.value || true}
            value={field.value}
            onChange={()=>{}}
          />
          <img style={{ width: '30px', height: '30px', borderRadius: '10px', opacity: field.value ? '1' : '0' }} src={CheckboxSelected} alt='checkbox ticked' />
        </SquircleDiv>
      }
      <SquircleLabel>
        {props.label}
      </SquircleLabel>
    </div>
  );
};

SquircleCheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object
};
