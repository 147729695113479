import {
  Button,
  FormikInput,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { StaticRoutes } from '../../Router';
import {
  Footer,
  Header,
  Loader,
  OtpEntryModal,
  SupportPopup
} from '../../components';
import TextLink from '../../components/Link/TextLink';
import { useModal } from '../../components/modalController/useModal';
import { Typography } from '../../components/typography/typography';
import {
  CommonDetails,
  FormContainer,
  FormFieldWrapper,
  phoneEmailRegex
} from '../../constants';
import { ValidationErrorMessageMap } from '../../constants/validationErrorMessageMap';
import { findFirstValidationError } from '../../helpers/formik';
import {
  authDetail,
  continueClickedState,
  requestLoginOtpState,
  requestOtpLogin,
  userDetails,
  verifyLoginOtp,
  verifyLoginOtpState
} from '../../recoil';
import { useTealium } from '../../tealium/useTealium';
import styles from './Login.module.css';

const validationSchema = yup.object({
  msisdn: yup.string().matches(phoneEmailRegex, 'Mobile number is not valid').required('Mobile number is required')
});

export const Login = () => {

  const userInfo = useRecoilValue(userDetails);
  const otpPending = useRef(false);
  const [otpResponse, setOtpRequest] = useRecoilState(requestOtpLogin);
  const [verifyOtpResponse, setOtpVerification] = useRecoilState(verifyLoginOtp);
  const [getAuthDetail, setAuthDetail] = useRecoilState(authDetail);
  const navigate = useNavigate();
  const location = useLocation();
  const [continueClicked, setContinueClicked] = useRecoilState(continueClickedState);
  const [openModal, closeModal] = useModal();
  const resetOtpRequestState = useResetRecoilState(requestLoginOtpState);
  const resetOtpResponseState = useResetRecoilState(verifyLoginOtpState);
  const resetLogin = useSetRecoilState(authDetail);
  const { track, events } = useTealium();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
  };

  useEffect(() => {
    track(events.login.pageView());
  }, []);

  const sendOtpRequest = (userDetailsInput) => {

    if (!otpPending.current) {
      otpPending.current = true;
      setOtpRequest(userDetailsInput);
    }
  };

  useEffect(() => {
    if (otpResponse) {
      otpPending.current = false;
    }
  }, [otpResponse]);

  useEffect(() => {
    if (verifyOtpResponse?.token) {
      track(events.login.loginComplete());
      setAuthDetail(verifyOtpResponse);
    }
  }, [verifyOtpResponse, setAuthDetail]);

  useEffect(() => {
    if (getAuthDetail?.token) {
      // navigate is still not working
      // I just wasted 3 days trying to fix this. Defer investigation until later.
      // I suspect that the issue is that one of the atoms in login.js retains an error
      // state, even after logging in, so when the login is completed the console is
      // flooded with VlUnauthorized errors. Setting the window location as below
      // forces the browser to refresh, and all atoms are refreshed.
      if(!getAuthDetail.verification.isVerified) {
        navigate(StaticRoutes.IdentityVerification);
      } else {
        navigate(StaticRoutes.FundingApplicationDetails);
      }
      closeOtpModal();
    }
  }, [getAuthDetail]);

  const DetailsEntry = () => {

    const formRef = useRef();

    const handleSubmit = () => {
      if (formRef.current) {
        track(events.login.continue());
        formRef.current.handleSubmit();
      }
    };

    const submitDetails = (formValues) => {
      setContinueClicked(true);
      sendOtpRequest({ contact: formValues.msisdn });
    };

    const goToTermsConditionsPage = () => {
      track(events.login.termsAndConditions());
      window.open(CommonDetails.vodalendTermsConditions, '_blank');
    };

    const goToPrivacyPolicyPage = () => {
      track(events.registration.TermConditionPrivacyPolicy('privacy policy'));
      window.open(CommonDetails.vodalendPrivacyPolicy, '_blank');
    };

    const goToRegistration = () => {
      track(events.login.notRegistered());
      navigate(StaticRoutes.registration);
    };

    const handleFormikTouchedErrors = (touched, errors) => {
      const errorMessage = findFirstValidationError(errors, touched);

      if (!errorMessage) {
        return;
      }

      track(events.error('form', location.pathname, errorMessage));
    };
    return (
      <div className={styles.loginFormWrapper}>

        <Typography align={'center'} level={3} family={'medium'} className={styles.heading}>
          Log in to your account
        </Typography>

        <Typography align={'center'} weight={'medium'} className={styles.subHeading}>
          Not registered?&nbsp;
          <span
            className={styles.boldRegistrationLink}
            onClick={goToRegistration}
            onKeyDown={goToRegistration}
            role='presentation'
          >
            Get started
          </span>
        </Typography>

        <ThemeProvider theme={theme.light}>
          <Formik
            initialValues={userInfo}
            onSubmit={submitDetails}
            validationSchema={validationSchema}
            validateOnMount={true}
            innerRef={formRef}
          >
            {({ errors, touched }) => {
              handleFormikTouchedErrors(touched, errors);

              return (
                <div className={styles.formik}>
                  <Form style={FormContainer} onFocus={handleFormFocus}>
                    <div style={FormFieldWrapper}>
                      <div className={styles.formContainerInput}>
                        <FormikInput label='Mobile number' name='msisdn' />
                      </div>
                    </div>
                    <Footer
                      rightCallback={errors.msisdn ? null : handleSubmit}
                      rightTitle={'Continue'}
                    />
                  </Form>
                </div>
              );
            }}
          </Formik>
        </ThemeProvider>
        <div className={styles.terms}>
          By continuing, I agree to the Vodacom
          <TextLink linkText='Terms and conditions' onClick={goToTermsConditionsPage} />
          &
          <TextLink linkText='privacy policy' onClick={goToPrivacyPolicyPage} />
        </div>
        <SupportPopup />
      </div>
    );
  };

  const closeOtpModal = () => {
    closeModal();
    resetOtpResponseState();
    resetOtpRequestState();
    setContinueClicked(false);
  };

  const resetAllResponse = () => {
    resetOtpResponseState();
    resetOtpRequestState();
  };

  const submitOtp = (otp) => {
    track(events.otp.continue());
    setOtpVerification(otp);
  };

  const resendOtp = () => {
    track(events.otp.resend());
    setOtpRequest(null);
  };

  useEffect(() => {

    const errorMsg = ValidationErrorMessageMap[verifyOtpResponse?.message] ||
      ValidationErrorMessageMap[otpResponse?.message] || otpResponse?.error?.message || verifyOtpResponse?.error?.message;

    if (errorMsg) {

      if (errorMsg === ValidationErrorMessageMap.InvalidLoginRef) {
        resetLogin(null);
        openModal({
          title: 'Error',
          children: <div dangerouslySetInnerHTML={{ __html: 'Please Try again' }} />,
          type: 'error',
          onCloseModal: closeOtpModal,
          actions: [<Button key="close" onClick={closeOtpModal}>Close</Button>]
        });
        return;
      }

      if (errorMsg === 'The request was sent but no response was received') {

        openModal({
          title: 'Error',
          children: <div dangerouslySetInnerHTML={{ __html: ValidationErrorMessageMap[errorMsg] }} />,
          type: 'error',
          onCloseModal: closeOtpModal,
          actions: [<Button key="close" onClick={closeOtpModal}>Try again</Button>]
        });
        return;
      }

      openModal({
        title: 'Error',
        children: <div dangerouslySetInnerHTML={{ __html: errorMsg }} />,
        type: 'error',
        onCloseModal: closeOtpModal,
        actions: [<Button key="close" onClick={closeOtpModal}>Close</Button>]
      });
      return;
    }

    if (continueClicked) {
      openModal({
        children: <OtpEntryModal
          maskedContact={otpResponse?.maskedContact}
          verifyOtpResponse={verifyOtpResponse}
          otpResponse={otpResponse}
          submitOtp={submitOtp}
          resendOtp={resendOtp}
          resetAllResponse={resetAllResponse}
          onView={() => track(events.otp.pageView())}
        />,
        onCloseModal: closeOtpModal
      });
      return;
    }
  }, [otpResponse, continueClicked, verifyOtpResponse]);

  return (
    <>
      {verifyOtpResponse?.token &&
        <Loader height={'30%'} />
      }
      {!verifyOtpResponse?.token &&
        <div className={styles.loginContainer}>
          <Header />
          <DetailsEntry />
        </div>
      }
    </>
  );
};
