import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => {
  const response = await vodaLend.getProductList();
  return response.products;
};

export const productListFetch = selector({
  key: 'productListFetch',
  get: async () => await fetchState()
});

const productListDefault = selector({
  key: 'productListDefault',
  get: async () => await fetchState()
});

export const productState = atom({
  key: 'productState',
  default: productListDefault
});

export const productSubmit = selector({
  key: 'productSubmit',
  get: async ({ get }) => {
    return await get(productState);
  },
  set: ({ get, set }, inputData) => {

    const cur = get(productState) || {};

    if (inputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...inputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(productState, data);
      return;
    }
    set(productState, {
      ...cur,
      ...inputData
    });
  }
});