import { atom } from 'recoil';

export const modalState = atom({
  key: 'modalState',
  default: {
    isOpen: false,
    type: null,
    title: null,
    header: null,
    children: null,
    footer: null
  }
});
