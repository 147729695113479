import dayjs from 'dayjs';

export const msisdnRegex = /^(\+?27|0)[6-9][0-9][0-9]{7}$/;

export const landlineRegex = /^(27|\+27|0)(\d{9})$/;

export const emailRegex = /^[A-Za-z0-9.]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

export const phoneEmailRegex = /^(\+?27|0)[6-9][0-9][0-9]{7}$|^[A-Za-z0-9.%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

export const rsaIdRegex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/;

export const rsaPassportRegex = /[A|D|M|T][0-9]{8}/;

export const nonRsaPassportRegex = /^(?!^0+$)[a-zA-Z0-9]{6,13}$/;

export const privateCompanyRegNumRegex = /^[0-9]{4}\/[0-9]{6,7}\/(07)$/;

export const publicCompanyRegNumRegex = /^[0-9]{4}\/[0-9]{6,7}\/(06)$/;

export const closedCorpCompanyRegNumRegex = /^[0-9]{4}\/[0-9]{6,7}\/(23)$/;

export const vatRegex = /^[4][0-9]{9}$/;

export const websiteRegex = /(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?/;

export const accountNumberRegex = /^[0-9]{9,18}$/;

export const namesRegex = /^[a-zA-Z ]+$/;

export const isValidCheckSum = (id) => id.split('').reduce((sum, d, i) => {
  let v = +d;
  if (i % 2 === 1) { v = v * 2; }
  if (v > 9) { v = v - 9; }
  return sum + v;
}, 0) % 10 === 0;

export const isValidSaIdDob = (id, dob) => {
  const testDob = dayjs(dob).format('YYYYMMDD');
  const idDob19 = dayjs(`19${id.slice(0, 6)}`).format('YYYYMMDD');
  const idDob20 = dayjs(`20${id.slice(0, 6)}`).format('YYYYMMDD');

  return !dob || !dayjs(dob).isValid() || (idDob19 === testDob) || (idDob20 === testDob);
};

export const minimumAge = 568025136000;

export const isValidSaIdGender = (id, gender) => {

  const idGender = +id[6];
  return !/^Male$|^Female$/i.test(gender) || (/^Female$/i.test(gender) && idGender < 5) || (/^Male$/i.test(gender) && idGender >= 5);
};

export const postalCodeRegex = /^[0-9]{4}$/;

export const revenueRegex = /^\d+$/;

export const onlyYearMonthRegex = /^\d{4}-(0[1-9]|1[0-2])$/;
