import React, {
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react';
import {
  Colors,
  CommonDetails,
  submissionStates
} from '../../../constants';
import {
  DocumentCard,
  FileUpload,
  SupportPopup
} from '../../../components';
import {
  documentData,
  useIsMobile,
  documentSync,
  documentsByType,
  useShowToast,
  documentsListState,
  toasts
} from '../../../recoil';
import {
  useRecoilValue,
  useRecoilCallback,
  useResetRecoilState
} from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { useOutletContext } from 'react-router-dom';
import { formatBytes } from '../../../helpers';
import styles from './DocumentDetails.module.css';
import { Typography } from '../../../components/typography/typography';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { useTealium } from '../../../tealium/useTealium';

const DOCTYPE_STATEMENT = 'statement';
const VALID_UPLOAD_BYTES_SIZE = 10 * (1024 * 1024);

export const DocumentDetails = () => {

  const { submitState, setSubmitState } = useOutletContext();
  const statementFileIdRef = useRef(uuidv4());
  const statementFilesList = useRecoilValue(documentsByType(DOCTYPE_STATEMENT));
  const statementFile = useRecoilValue(documentData(statementFileIdRef.current));
  const isMobile = useRecoilValue(useIsMobile);
  const docInfoStyle = useMemo(() => isMobile ? { ...styles.docInfo, ...styles.docInfoMobile } : styles.docInfo, [isMobile]);
  const isValid = useMemo(() => statementFilesList.length > 0, [statementFilesList]);
  const [isAllowSize, setIsAllowSize] = useState(false);
  const [isAllowType, setIsAllowType] = useState(false);
  const showToast = useShowToast();
  const resetToast = useResetRecoilState(toasts);
  const resetDocList = useResetRecoilState(documentsListState);
  const resetStatementFiile = useResetRecoilState(documentData(statementFileIdRef.current));
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.uploadDocuments.pageView());
  }, []);

  useFindErrorOnState([documentsByType(DOCTYPE_STATEMENT), documentData(statementFileIdRef.current)], () => {
    resetDocList();
    resetStatementFiile();
    setSubmitState(submissionStates.canSubmit);
  });

  useEffect(() => {

    if (statementFilesList?.error || statementFile?.error) {
      resetToast();
      return;
    }
  }, [statementFilesList]);

  useEffect(() => {
    setSubmitState(submissionStates.default);
  }, []);

  useEffect(() => {
    if (isValid && submitState === submissionStates.default) {
      setSubmitState(submissionStates.canSubmit);
    } else if (!isValid && submitState === submissionStates.canSubmit) {
      setSubmitState(submissionStates.default);
    } else if (isValid && submitState === submissionStates.startSubmit) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [isValid, submitState]);

  const uploadFile = useRecoilCallback(({ snapshot, set }) => async (fileRef, docName, docType, file) => {
    set(documentSync({ docName, docType }), file);
    return await snapshot.getPromise(documentData(fileRef.current));
  }, []);

  const getUniqueFileName = (filename) => {
    if (statementFilesList.find((file) => file.docName === filename)) {
      const splitName = filename.split('.');
      let baseName = splitName[0];
      const extension = splitName[1];
      let pattern = /\((\d+)\)$/;

      if (pattern.test(baseName)) {
        const match = pattern.exec(baseName);
        if (match) {
          let originalDigits = parseInt(match[1]);
          let newDigits = originalDigits + 1;
          let newString = baseName.replace(/\((\d+)\)$/, `(${newDigits})`);
          filename = `${newString}.${extension}`;
          return getUniqueFileName(filename);
        }
      } else {
        baseName += ' (1)';
        filename = `${baseName}.${extension}`;
        return getUniqueFileName(filename);
      }
    }
    return filename;
  };

  const uploadStatement = async (statementFile) => {
    if (!(statementFile.size <= VALID_UPLOAD_BYTES_SIZE)) {
      setIsAllowSize(true);
      return;
    }
    if (!(statementFile.type === 'application/pdf')) {
      setIsAllowType(true);
      return;
    }

    let uniquefilename = getUniqueFileName(statementFile.name);
    await uploadFile(statementFileIdRef, uniquefilename, DOCTYPE_STATEMENT, statementFile);
    showToast('Upload successful');
    track(events.uploadDocuments.nextClick());
    statementFileIdRef.current = uuidv4();
  };

  useEffect(() => {
    if (statementFile) {
      setIsAllowSize(false);
      setIsAllowType(false);
      statementFile.forEach(file => {
        uploadStatement(file);
      });
    }
  }, [statementFile]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography level={3} family={'medium'} align={'center'} className={styles.titleHeading} spacing={5}>
          We need the following documents
        </Typography>
        <Typography size={'b1'} align={'center'}>
          Please attach your latest bank statements not older than 3 months
        </Typography>
      </div>
      <div className={styles.documentCardContainer}>
        {statementFilesList.map((f) => (<DocumentCard key={f.docId} docName={f.docName} docId={f.docId} docSize={f.docSize} />))}
      </div>

      <div className={styles.uploadContainer}>
        <div className={styles.heading}>
          Bank statement upload
        </div>
        <div className={styles.fileUploadContainer}>
          <FileUpload fileId={statementFileIdRef.current} onUpload={() => track(events.uploadDocuments.documentUpload())}/>
          <div className={styles.docInfoContainer}>
            <div className={docInfoStyle}>
              <div className={styles.custDocDotContainer}>
                <div className={styles.custDocDot} />
              </div>
              <div>
                <span className={styles.docInfoBoldTxt}>Bank statement: </span>
                A clear copy of your Bank statement not older than 3 months.
              </div>
            </div>
            <div className={styles.sizeFormat}>
              <div className={styles.docSizeLimitInfo}>
                <span className={styles.boldText}>Maximum size:</span> {formatBytes(VALID_UPLOAD_BYTES_SIZE)}
              </div>
              <div className={styles.docSizeLimitInfo}>
                <span className={styles.boldText}>File Type:</span> PDF
              </div>
            </div>
          </div>
        </div>
        {isAllowSize &&
          <div className={styles.errorMessage}>*Invalid file size. File size should be smaller than {formatBytes(VALID_UPLOAD_BYTES_SIZE)}.</div>
        }
        {isAllowType &&
          <div className={styles.errorMessage}>*Invalid file type. File type should be PDF.</div>
        }
      </div>

      <div className={styles.pageBottomTxtContainer}>
        <div>
          We use industry-leading encryption and security methods when handling your documents.
        </div>
        <div>
          Your details are not shared with anyone. To find out more, read our <a style={{ color: Colors.Primary4 }} href={CommonDetails.vodalendPrivacyPolicy} target="_blank" rel="noreferrer"><u>privacy
            statement</u></a>.
        </div>
      </div>
      <SupportPopup />
    </div>
  );
};
