import {
  FormikInput,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useRef
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../../recoil';
import {getVerificationStatus} from '../../api/vodaLend';
import {
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import {ValidationSchema} from './ValidationSchema';
import { ApplicationRoutes } from '../../Router';
import {
  ApplyHeader,
  Footer
} from '../../components';
import { useLoader } from '../../components/Loader/useLoader';
import { Typography } from '../../components/typography/typography';
import {
  ApplicationSteps,
  FormField,
  FormFieldError
} from '../../constants';
import { findFirstValidationError } from '../../helpers/formik';
import { FormRadioGroup } from '../../components/FormRadioGroup/FormRadioGroup';
import styles from './IdentityVerification.module.css';

export const IdentityVerification = () => {
  const isMobile = useRecoilValue(useIsMobile);
  const [openLoader, closeLoader] = useLoader();
  const navigate = useNavigate();
  const FormInitialState = {idType: 'id', idNumber: '', passportNumber: ''};
  const wizardSteps = Object.values(ApplicationSteps).map((s) => ({
    label: s.title,
    subSteps: 1
  }));

  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
  };

  const DetailsEntry = () => {

    const formRef = useRef();

    const handleSubmit = async (formValues) => {
      let verificationStatus;
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
      openLoader();
      verificationStatus = await getVerificationStatus({
        identificationType: formValues.idType,
        identificationNumber: formValues.idNumber || formValues.passportNumber
      });
      if(verificationStatus.statusText === 'OK') {
        navigate(ApplicationRoutes.personalDetails);
      }
      closeLoader();
    };

    const handleFormikTouchedErrors = (touched, errors) => {
      const errorMessage = findFirstValidationError(errors, touched);
      if (!errorMessage) {
        return;
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <Typography level={3} family={'medium'} align={'center'} className={styles.titleHeading} spacing={5}>
          Please enter Your ID or Passport number
          </Typography>
          <Typography size={'b1'} align={'center'} className={styles.titleSubHeading} >
            {'To carry out an account check, we must first \n verify the information you give us.'}
          </Typography>
        </div>
        <ThemeProvider theme={theme.light}>
          <Formik
            initialValues={FormInitialState}
            validationSchema={ValidationSchema}
            validateOnMount={true}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values, errors, touched }) => {
              handleFormikTouchedErrors(touched, errors);
              return (
                <div className={styles.formik}>
                  <Form className={styles.formContainer} onBlur={handleFormFocus}>
                    {
                      values.idType === 'id'
                        ?
                        <div>
                          <FormikInput label='SA ID number' name='idNumber' />
                        </div>
                        :
                        <div>
                          <FormikInput label='Passport number' name='passportNumber' />
                        </div>
                    }
                    <FormRadioGroup name='idType' values={{
                      id: 'SA ID Number',
                      pp: 'Passport'
                    }}
                    />
                    {values.idType !== 'id' &&
                      <div style={{
                        ...(errors?.passportCountry ? FormFieldError : FormField),
                        ...(isMobile ? styles.mobileCountryOfIssueField : styles.countryOfIssueField)
                      }} />
                    }
                    <Footer rightCallback={Object.values(errors).length ? null : () => handleSubmit(values, errors)} rightTitle={'Continue'} />
                  </Form>
                </div>
              );
            }}
          </Formik>
        </ThemeProvider>
      </div>
    );
  };

  return (
    <div className={styles.loginPage}>
      <ApplyHeader
        currentStep={1}
        activeSubStep={1}
        steps={wizardSteps}
      />
      <DetailsEntry />
    </div>
  );
};
