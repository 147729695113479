import { getIn } from 'formik';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
import { Calendar, ErrorIconRed } from '../../assets';
import { Colors } from '../../constants';
import { ShowIf } from '../ShowIf/ShowIf';

const ParentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: inherit;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 2.5%;
  border: 1px solid #D8DBE0;
  background: ${Colors.GreyScale2};
`;

const Label = styled.span`
  position: absolute;
  width: inherit;
  max-width: 100%;
  font-size: 16px;
  margin: 25px 0 0 0;
  color: ${Colors.GreyScale13};
  transform: ${props => (props.focussed ? 'translateY(-20px) scale(0.75)' : '')};
  transform-origin: ${props => (props.focussed ? 'top left' : '')};
  padding: ${props => (props.focussed ? '0 0 0 60px' : '0 0 0 30px')};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  transition-duration: 150ms;
`;

const StyledDatePicker = styled(DatePicker)`

  &.react-date-picker{
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    z-index: 2;
    display: flex;

    & > :nth-child(1){
      
      color: ${props => props.focussed ? 'black' : 'transparent'};           // to change the color of / / of DatePicker
      border-radius: 4px;
      width: 100%;
      height: 65px;
      border: 0;
      background-color: transparent;

      & > :nth-child(1){
        
        position: relative;
        bottom: -6px;
        input {
          background-color: transparent;
          outline: none;
        }
      }

      & > :nth-child(2){
        
        position: relative;
        bottom: -6px;
        padding: 4px 0;
        input {
          background-color: transparent;
          outline: none;
        }
        z-index: 2;
        display: flex;
      }
    }
  }

`;

const HR = styled.hr`
  width: 95%;
  border-radius: 2px;
  margin: 0;
  height: 0.5px;
  background-color: ${props => props.error ? Colors.Primary4 : Colors.GreyScale13};
  position: absolute;
  top: 65px;
  border: 0;
`;

const SubMessage = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  display: flex;
  column-gap: 0.25rem;
  color: ${Colors.Primary4};
  width: 100%;
`;

const stringFormat = 'yyyy-MM-dd';
const stringFormatForMonthYearOnly = 'yyyy-MM';

export const DateSelector = ({ field, form, showOnlyMonthYear = false, ...props }) => {

  const [isFocussed, setIsFocussed] = useState(false);
  const touched = useMemo(() => getIn(form?.touched, field.name), [form?.touched, field.name]);
  const error = useMemo(() => getIn(form?.errors, field.name), [form?.errors, field.name]);

  const setCalendarDate = (value, dateFormat) => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(
      field.name,
      value ? DateTime.fromJSDate(value).toFormat(dateFormat) : ''
    );
    form.validateForm(form.values);
  };

  return (
    <ParentDiv
      onFocus={() => setIsFocussed(true)}
      onBlur={() => setIsFocussed(false)}
    >

      <Label
        focussed={isFocussed || !!field.value}
      >
        {props.label}
      </Label>
      {showOnlyMonthYear ?
        <StyledDatePicker
          onChange={(value) => {
            setCalendarDate(value, stringFormatForMonthYearOnly);
          }}
          maxDetail={'year'}
          name={field.name}
          focussed={isFocussed || !!field.value}
          calendarIcon={
            <img src={Calendar} className="icon-calendar" alt='calendarIcon' />
          }
          clearIcon={null}
          yearPlaceholder={isFocussed ? '----' : ''}
          monthPlaceholder={isFocussed ? '--' : ''}
          format="yyyy/MM"
          value={field.value ? DateTime.fromFormat(field.value, stringFormatForMonthYearOnly).toJSDate() : field.value}
          onCalendarClose={() => {
            form.setFieldTouched(field.name, true);
            form.validateForm(form.values);
          }}
        />
        :
        <StyledDatePicker
          name={field.name}
          focussed={isFocussed || !!field.value}
          disabled={false}
          calendarIcon={
            <img src={Calendar} className="icon-calendar" alt='calendarIcon' />
          }
          clearIcon={null}
          yearPlaceholder={isFocussed ? '----' : ''}
          monthPlaceholder={isFocussed ? '--' : ''}
          dayPlaceholder={isFocussed ? '--' : ''}
          format="dd/MM/y"
          value={field.value ? DateTime.fromFormat(field.value, stringFormat).toJSDate() : field.value}
          onChange={(value) => {setCalendarDate(value, stringFormat);}}
          onCalendarClose={() => {
            form.setFieldTouched(field.name, true);
            form.validateForm(form.values);
          }}
          minDate={props.minDate ? props.minDate : null}
          maxDate={props.maxDate ? props.maxDate : null}
        />
      }
      <HR error={touched && error} />

      <ShowIf condition={!!(touched && error)}>
        <SubMessage>
          <img
            src={ErrorIconRed}
            alt="error"
          />
          {error}
        </SubMessage>
      </ShowIf>
    </ParentDiv>
  );
};

DateSelector.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  showOnlyMonthYear: PropTypes.bool
};