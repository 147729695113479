import { StatusCodes } from 'http-status-codes';
import { handleErrorResponse } from '../../helpers/handleErrorResponse';
import { axiosInstance } from './axiosInstance';

const requestOtp = async (data) => {
  let response;

  try {
    response = await axiosInstance.post('/vl-oauth-code-exchange/register', data);
  } catch (err) {
    const errorResp = err?.response?.data?.message;
    if (errorResp) {
      response = err.response;
    } else {
      response = handleErrorResponse(err, data);
    }
  }

  return response;
};

export const requestRegisterOtp = async ({
  firstName,
  lastName,
  email,
  msisdn,
  contact,
  ref,
  marketingConsent
}) => await requestOtp({
  firstName,
  lastName,
  email,
  msisdn,
  contact,
  ref,
  marketingConsent
});

export const requestRegisterOtpResend = async (input) => {
  const response = await requestOtp(input);
  return response;
};

export const getVerificationStatus = async (data) => {
  try {
    return await axiosInstance.post('/verification/identity', data);
  } catch (err) {
    const errorResponse = err.response || {};

    if ([StatusCodes.BAD_REQUEST, StatusCodes.TOO_MANY_REQUESTS].includes(errorResponse.status)) {
      return {
        data: errorResponse.data || {}
      };
    }

    if (errorResponse.status === StatusCodes.REQUEST_TIMEOUT) {
      return {
        data: { message: errorResponse.data?.message || {}}
      };
    }

    return handleErrorResponse(err);
  }
};

export const submitRegisterOtpResponse = async (ref, code) => {

  let response;

  try {

    response = await axiosInstance.post('/vl-oauth-code-exchange/register', { ref, code });

    if (response.status !== StatusCodes.CREATED || !response.data) {
      throw new Error('SubmitOtpResponse');
    }

  } catch (err) {
    const errorResponse = err.response || {};
    if ([StatusCodes.BAD_REQUEST, StatusCodes.TOO_MANY_REQUESTS].includes(errorResponse.status)) {
      response = {
        data: errorResponse.data || {}
      };
    } else if (errorResponse.status === StatusCodes.REQUEST_TIMEOUT) {
      response = {
        data: { message: errorResponse.data?.message|| {} }
      };
    } else {
      response = handleErrorResponse(err);
    }
  }

  const data = response.data;

  if (!data.accessToken) {
    return data;
  }

  return {
    token: data.accessToken,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    msisdn: data.msisdn,
    ttl: data.ttl,
    verification: data.verification,
    timestamp: new Date().getTime(),
    marketingConsent: data.marketingConsent
  };
};
