import * as yup from 'yup';

export const ValidationSchema = yup.object({
  loanAmount: yup
    .number('Loan Amount only accepts numbers')
    .integer('Loan Amount only accepts numbers')
    .required('Loan Amount is a required field')
    .positive('Loan Amount only accepts positive numbers'),
  businessType: yup
    .mixed().test('businessType', 'Business Type is required', (item) => !!item?.value),
  timeInBusiness: yup
    .date()
    .required('Duration In Business is a required field')
    .max(new Date(), 'Duration In Business should be in past'),
  revenue: yup
    .mixed().test('revenue', 'Business Income is required', (item) => !!item?.value)
});