import {
  axiosInstance
} from './axiosInstance';
import { handleErrorResponse } from '../../helpers/handleErrorResponse';

export const getFundingDetails = async () => {
  let response;

  try {
    response = await axiosInstance.get('/funding');
  } catch (err) {
    response = handleErrorResponse(err);
  }

  return response;
};

export const postFundingDetails = async (data) => {
  let response;

  try {
    response = await axiosInstance.post('/funding', data);
  } catch (err) {
    response = handleErrorResponse(err, data);
  }

  return response;
};

export const updateFundingDetails = async (data) => {
  let response;

  try {
    response = await axiosInstance.patch('/funding', data);
  } catch (err) {
    response = handleErrorResponse(err, data);
  }

  return response;
};
