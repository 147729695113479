import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { FormikInputField } from '../v3/InputField/FormikInput';
import { FormikSelectField } from '../v3/SelectField/FormikSelect';
import { Checkbox } from '@vfs-digital-channels/ns-react-components-new';
import { NewDateSelector } from '../../components/NewDateSelector/NewDateSelector';
import {
  NewFooter
} from '../../components';
import styles from './FormBuilder.module.css';

export const FieldTypes = {
  INPUT: 'input',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  DATESELECTOR: 'DateSelector',
  PAIRED_FIELDS: 'paired_fields',
  CATEGORY_WITH_HEADING: 'fieldHeading',
  DYNAMIC_BUILDING_FIELDS: 'dynamic_building_fields',
  DYNAMIC_ID_FIELDS: 'dynamic_id_fields',
  CHECKBOX_WITH_INPUT: 'checkbox_with_input',
  LABEL: 'Label'
};

export const FormBuilder = ({
  fields,
  title,
  subtitle,
  isMobile,
  values,
  FormField,
  errors,
  submitState,
  isDetailsCaptured,
  backClick,
  submissionStates,
  nextClick,
  touched
}) => {

  const renderDynamicIdFields = (idTypeField) => {
    if (!values?.idType) {
      return null;
    }
    const selectedIdFields = idTypeField[values?.idType];
    if (!selectedIdFields) {
      return null;
    }
    return selectedIdFields.map((field, index) => (
      <React.Fragment key={index}>
        {renderField(field)}
      </React.Fragment>
    ));
  };

  const renderDynamicBuildingFields = (buildingTypeFields) => {
    if (!values?.buildingType) {
      return null;
    }
    const selectedTypeFields = buildingTypeFields[values?.buildingType];
    if (!selectedTypeFields) {
      return null;
    }

    return selectedTypeFields.map((field, index) => (
      <React.Fragment key={index}>
        {renderField(field)}
      </React.Fragment>
    ));
  };

  const getErrorClassName = (hasError, fieldType) => {
    if(hasError) {
      return styles.errorContainer;
    } else if(fieldType !== 'paired') {
      return styles.fieldContainer;
    } else {
      return '';
    }
  };

  const renderField = (field, fieldType) => {

    const hasError = Object.keys(touched).includes(field?.props?.name) && errors[field?.props?.name];
    const fieldClassName = getErrorClassName(hasError, fieldType);
    switch (field.type) {
      case FieldTypes.INPUT:
        return (
          <div style={FormField}>
            {field.label && (
              <div className={styles.fieldLabel}>
                {field.label}
              </div>
            )}
            <Field component={FormikInputField} {...field.props} className={fieldClassName} />
          </div>
        );

      case FieldTypes.SELECT:
        return (
          <div style={FormField}>
            {field.label && (
              <div className={styles.fieldLabel}>
                {field.label}
              </div>
            )}
            <Field component={FormikSelectField} {...field.props} className={fieldClassName}/>
          </div>
        );
      case FieldTypes.CATEGORY_WITH_HEADING:
        return (
          <>
            <div className={styles.categoryHeading}>
              {field.heading}
            </div>
          </>
        );
      case FieldTypes.LABEL:
        return(
          <>
            <div className={styles.label}>
              {field.label}
            </div>
          </>
        );

      case FieldTypes.CHECKBOX:
        return (
          <div className={styles.formFieldRadioContainer}>
            <Field
              component={Checkbox}
              {...field.props}
              checked={values[field.props.name]}
              className={fieldClassName}
            />
          </div>
        );

      case FieldTypes.DATESELECTOR:
        return (
          <div className={styles.formFieldDateSelector}>
            <Field
              component={NewDateSelector}
              {...field.props}
              error={hasError}
            />
          </div>
        );

      case FieldTypes.PAIRED_FIELDS:
        return (
          <div className={styles.fieldRow}>
            <div className={styles.halfWidth} >
              {renderField(field.fields[0], 'paired')}
            </div>
            <div className={styles.halfWidth}>
              {renderField(field.fields[1], 'paired')}
            </div>
          </div>
        );
      case FieldTypes.DYNAMIC_BUILDING_FIELDS:
        return renderDynamicBuildingFields(field.fields);

      case FieldTypes.DYNAMIC_ID_FIELDS:
        return renderDynamicIdFields(field.fields);

      case FieldTypes.CHECKBOX_WITH_INPUT:

        return (
          <>
            <div className={styles.formFieldRadioContainer}>
              <Field
                component={Checkbox}
                {...field?.checkbox}
                checked={values[field?.checkbox?.name]}
              />
            </div>
            {values[field?.checkbox?.name] && (
              <div style={FormField}>
                <Field
                  component={FormikInputField}
                  name={field?.conditionalInput?.name}
                  label={field?.conditionalInput?.label}
                  placeholder={field?.conditionalInput?.placeholder}
                  className={errors[field.conditionalInput.name] ? styles.errorContaine : ''}
                />
              </div>
            )}
          </>
        );

      default:
        return null;
    }
  };

  const renderFormFields = () => {
    return fields?.map((field, index) => {
      if (field?.props?.name === 'buildingTypes') {
        return (
          <React.Fragment key={index}>
            {renderField(field)}
            {fields?.map((f) => {
              if (f.type === FieldTypes.DYNAMIC_BUILDING_FIELDS) {
                return renderDynamicBuildingFields(f.fields);
              }
              return null;
            })}
          </React.Fragment>
        );
      }

      if (field.type !== FieldTypes.DYNAMIC_BUILDING_FIELDS) {
        return <React.Fragment key={index}>{renderField(field)}</React.Fragment>;
      }
      if (field.type !== FieldTypes.DYNAMIC_ID_FIELDS) {
        return <React.Fragment key={index}>{renderField(field)}</React.Fragment>;
      }

      return null;
    });
  };

  return (
    <div className={styles.container}>
      <div className={isMobile ? styles.mobileTitleContainer : styles.titleContainer}>
        <div className={isMobile ? styles.mobileTitleHeading : styles.titleHeading}>
          {title}
        </div>
        {subtitle && (
          <div className={styles.titleSubHeading}>
            {subtitle}
          </div>
        )}
      </div>

      {/* Form Fields */}
      <div className={styles.formWrapper}>
        { renderFormFields()}
      </div>
      <NewFooter
        leftTitle={ isMobile ? '' : 'Back'}
        rightTitle={isDetailsCaptured ? 'Next' : 'Confirm and continue'}
        leftCallback={backClick}
        rightCallback={submitState === submissionStates?.canSubmit ? nextClick : null}
      />
    </div>
  );
};

FormBuilder.propTypes = {

  fields: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isMobile: PropTypes.bool,
  values: PropTypes.object,
  FormField: PropTypes.object,
  errors: PropTypes.object,
  submitState: PropTypes.object,
  backClick: PropTypes.func,
  submissionStates: PropTypes.object,
  nextClick: PropTypes.func,
  isDetailsCaptured: PropTypes.func,
  touched: PropTypes.object
};