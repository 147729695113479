import { StatusCodes } from 'http-status-codes';
import { axiosInstance } from './axiosInstance';

export const getProductList = async () => {

  let response;

  try {

    response = await axiosInstance.get('/products');

    if (response.status !== StatusCodes.OK || !response.data) {

      throw new Error('GetProductListError');
    }

  } catch (error) {

    const errorResponse = error.response || {};

    if (errorResponse.status === StatusCodes.NOT_FOUND) {
      response = { data: null };
    } else {
      throw error;
    }
  }

  return response.data;
};